<template>
  <div class="risk-decrement">
    <div
      class="risk-decrement-project"
      :style="{ zIndex: isConfiguration ? 2000 : 2001 }"
    >
      <div class="flex-box flex-items flex-between" style="flex: 1">
        <div class="el-form demo-form-inline el-form--inline" style="flex: 1">
          <div class="el-form-item el-form-item--small">
            <div
              class="el-form-item__label"
              style="width: 80px; margin-left: 36px"
            >
              项目名称:
            </div>
            <div class="el-form-item__content">
              <el-select
                v-model="searchParam.projectId"
                filterable
                placeholder="请选择"
                @change="handleProjectSelect"
                clearable
              >
                <el-option
                  v-for="item in dictList.projectList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="el-form-item el-form-item--small">
            <div
              class="el-form-item__label"
              style="width: 80px; margin-left: 36px"
            >
              客户名称:
            </div>
            <div class="el-form-item__content">
              <el-select
                v-model="searchParam.userId"
                filterable
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in dictList.customerList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="el-form-item el-form-item--small" v-if="false">
            <div
              class="el-form-item__label"
              style="width: 80px; margin-left: 36px"
            >
              设备类型:
            </div>
            <div class="el-form-item__content">
              <el-select
                v-model="searchParam.deviceTypeName"
                filterable
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in dictList.deviceTypeList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="el-form-item el-form-item--small">
            <div
              class="el-form-item__label"
              style="width: 80px; margin-left: 36px"
            >
              保险公司:
            </div>
            <div class="el-form-item__content">
              <el-cascader
                style="width: 300px"
                v-model="companys"
                :props="projectProps"
                popper-class="risk-decrement-form-cascader"
                @change="handleCompanySelect"
                :show-all-levels="true"
                separator="-"
                filterable
                clearable
              ></el-cascader>
            </div>
          </div>
        </div>
        <div style="border-right: 1px solid #dee3e6; padding-right: 16px">
          <!-- <el-button @click="handleTest">测试</el-button> -->
          <el-button
            type="primary"
            plain
            @click="handleAnew"
            class="blue-btn"
            icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            plain
            @click="handleReset"
            class="white-btn"
            icon="el-icon-refresh-left"
            >重置</el-button
          >
        </div>
        <div
          class="color-b pointer top-action-btn"
          @click="isConfiguration = true"
          style="margin-left: 10px"
        >
          <i class="el-icon-s-tools color-b"></i>数据设置
        </div>
      </div>
    </div>
    <div style="display: flex; flex: 1; justify-content: space-between">
      <CardBox
        v-if="dataSetting.ProjectIndicat.show"
        v-loading="dataSetting.ProjectIndicat.loading"
        style="flex: 1"
        title="项目指标"
      >
        <template slot="main">
          <ProjectIndicat
            :setting="dataSetting.ProjectIndicat.items"
            :data="dataSetting.ProjectIndicat.data"
          ></ProjectIndicat>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.Safety.show"
        v-loading="dataSetting.Safety.loading"
        style="flex: 1; margin-left: 10px"
        :border="false"
        title="安全指数"
      >
        <template slot="action">
          <div class="flex-box align-center">
            <div @click="gradeShow = true" class="Safety-record">
              评分记录
              <img
                style="width: 16px; height: 16px; margin-left: 4px"
                src="@/assets/images/RiskControl/records.png"
              />
            </div>
            <el-tooltip placement="top">
              <div slot="content">
                该评分主要考虑项目风险告警、出险案件、日均工时、设备保障4个维度综合评估，得分越高，越安全。
              </div>
              <div
                style="font-size: 14px; color: #666666"
                class="flex-box align-center"
              >
                评分说明
                <img
                  style="width: 16px; height: 16px; margin-left: 4px"
                  src="@/assets/images/RiskControl/idea.png"
                />
              </div>
            </el-tooltip>
          </div>
        </template>
        <template slot="main">
          <Safety
            :data="dataSetting.Safety.data"
            :innerShow.sync="gradeShow"
            :innerLoading="dataSetting.Safety.innerLoading"
          ></Safety>
        </template>
      </CardBox>
    </div>
    <div style="display: flex; justify-content: space-between">
      <CardBox
        v-if="dataSetting.PolicyOnline.show"
        v-loading="dataSetting.PolicyOnline.loading"
        style="width: 33%"
        title="保单在线"
      >
        <template slot="main">
          <PolicyOnline
            :data="dataSetting.PolicyOnline.data"
            :setting="dataSetting.PolicyOnline.items"
            :objSetting="dataSetting.PolicyOnline.objItems"
          ></PolicyOnline>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.EquipmentOnline.show"
        v-loading="dataSetting.EquipmentOnline.loading"
        style="width: 33%"
        title="设备在线"
        ><div slot="action">
          <el-select
            class="data-scope"
            v-model="dataSetting.EquipmentOnline.params.runnerUnit"
            placeholder="请选择"
            @change="dateSectionsChange($event, 'EquipmentOnline')"
          >
            <el-option
              v-for="item in dateSections3"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </div>
        <template slot="main">
          <EquipmentOnline
            :data="dataSetting.EquipmentOnline.data"
          ></EquipmentOnline>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.RiskWaring.show"
        v-loading="dataSetting.RiskWaring.loading"
        style="width: 33%"
        title="风险告警"
        ><div slot="action" class="flex-box flex-between flex-1 flex-items">
          <div style="margin-left: 4px">
            <el-tooltip
              popper-class="al-tool-tips"
              placement="top"
              effect="light"
              :visible-arrow="false"
            >
              <div class="alarmTips" slot="content">
                <div
                  class="alarmTips-item"
                  v-for="(tips, index) in alarmTips"
                  :key="index"
                >
                  <div class="alarmTips-item-type">{{ tips.type }}</div>
                  <div class="alarmTips-item-tips">{{ tips.tips }}</div>
                </div>
              </div>
              <img
                style="width: 20px; height: 20px"
                src="@/assets/images/RiskControl/alarm-icon.png"
              />
            </el-tooltip>
          </div>
          <div class="flex-box flex-items">
            <el-select
              v-model="dataSetting.RiskWaring.params.dateType"
              placeholder="请选择"
              class="data-scope"
              @change="dateSectionsChange($event, 'RiskWaring')"
            >
              <el-option
                v-for="item in dateSections5"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
            <div @click="goToMore('RiskWaring')" class="view-more">
              <span>查看更多</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <template slot="main">
          <RiskWaring :data="dataSetting.RiskWaring.data"></RiskWaring>
        </template>
      </CardBox>
    </div>
    <div
      v-if="$store.state.userInfo.standingKey == 1"
      style="display: flex; justify-content: space-between"
    >
      <CardBox
        v-if="dataSetting.ServiceOnline.show"
        v-loading="dataSetting.ServiceOnline.loading"
        style="width: 33%"
        title="服务在线"
      >
        <template slot="main">
          <ServiceOnline
            :data="dataSetting.ServiceOnline.data"
            :setting="dataSetting.ServiceOnline.items"
          ></ServiceOnline>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.CaseList.show"
        v-loading="dataSetting.CaseList.loading"
        style="width: 42%"
        title="案件列表"
      >
        <template slot="main">
          <CaseList :data="dataSetting.CaseList.data"></CaseList>
        </template>
        <div slot="action" class="flex-box flex-items">
          <el-select
            v-model="dataSetting.CaseList.params.rangeFlag"
            placeholder="请选择"
            class="data-scope"
            @change="dateSectionsChange($event, 'CaseList')"
          >
            <el-option
              v-for="item in dateSections4"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <div @click="goToMore('CaseList')" class="view-more">
            <span>查看更多</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </CardBox>
      <CardBox
        v-if="dataSetting.CaseAnalysis.show"
        v-loading="dataSetting.CaseAnalysis.loading"
        style="width: 24%"
        title="案件分析"
      >
        <div slot="action">
          <el-radio-group
            class="radio-group"
            @input="dateSectionsChange($event, 'CaseAnalysis')"
            v-model="dataSetting.CaseAnalysis.params.condition"
          >
            <el-radio
              v-for="(type, index) in caseAnalyzeListTypes"
              :key="type.key"
              :label="type.key"
              border
            >
              {{ type.value }}
            </el-radio>
          </el-radio-group>
        </div>
        <template slot="main">
          <CaseAnalysis :data="dataSetting.CaseAnalysis.data"></CaseAnalysis>
        </template>
      </CardBox>
    </div>
    <div
      v-if="$store.state.userInfo.standingKey == 1"
      style="display: flex; justify-content: space-between"
    >
      <CardBox
        v-if="dataSetting.RiskControlOnline.show"
        v-loading="dataSetting.RiskControlOnline.loading"
        style="width: 33%"
        title="风控在线"
      >
        <template slot="main">
          <RiskControlOnline
            :data="dataSetting.RiskControlOnline.data"
            :setting="dataSetting.RiskControlOnline.items"
          ></RiskControlOnline>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.RiskControlTrain.show"
        v-loading="dataSetting.RiskControlTrain.loading"
        style="width: 66.5%"
        title="风控培训"
      >
        <div slot="action" class="flex-box flex-items">
          <el-select
            v-model="dataSetting.RiskControlTrain.params.riskUnit"
            placeholder="请选择"
            class="data-scope"
            @change="dateSectionsChange($event, 'RiskControlTrain')"
          >
            <el-option
              v-for="item in dateSections3"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <div @click="goToMore('RiskControlTrain')" class="view-more">
            <span>查看更多</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <template slot="main">
          <RiskControlTrain
            :data="dataSetting.RiskControlTrain.data"
          ></RiskControlTrain>
        </template>
      </CardBox>
    </div>
    <div style="display: flex">
      <CardBox
        v-if="dataSetting.startBox.show"
        v-loading="dataSetting.startBox.loading"
        style="flex: 1; max-width: 50%"
        title="工况情况"
      >
        <div slot="action">
          <el-radio-group
            class="radio-group"
            @input="dateSectionsChange($event, 'startBox')"
            v-model="dataSetting.startBox.params.weatherOrWork"
          >
            <el-radio
              v-for="(type, index) in workOrWeatherTypes"
              :key="type.key"
              :label="type.key"
              border
            >
              {{ type.value }}
            </el-radio>
          </el-radio-group>
        </div>
        <template slot="main">
          <div
            v-if="dataSetting.startBox.params.weatherOrWork === 'W'"
            class="flex-box flex-items"
            style="justify-content: flex-end; margin: 10px 16px"
          >
            <el-select
              v-model="dataSetting.startBox.params.runnerUnit"
              placeholder="请选择"
              class="data-scope"
              @change="dateSectionsChange($event, 'startBox')"
            >
              <el-option
                v-for="item in dateSections2"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
          <startBox :data="dataSetting.startBox.data"></startBox>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.manHour.show"
        v-loading="dataSetting.manHour.loading"
        style="flex: 1; margin-left: 10px"
        title="工时分布"
      >
        <div slot="action" class="flex-box flex-items">
          <el-select
            v-model="dataSetting.manHour.params.runnerUnit"
            placeholder="请选择"
            @change="dateSectionsChange($event, 'manHour')"
            class="data-scope"
          >
            <el-option
              v-for="item in dateSections2"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <div @click="goToMore('manHour')" class="view-more">
            <span>查看更多</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <template slot="main">
          <manHour :data="dataSetting.manHour.data"></manHour>
        </template>
      </CardBox>
    </div>
    <div style="display: flex; justify-content: space-between">
      <CardBox
        v-if="dataSetting.DriverOnline.show"
        v-loading="dataSetting.DriverOnline.loading"
        style="width: 33%"
        title="机手在线"
      >
        <template slot="main">
          <DriverOnline
            :data="dataSetting.DriverOnline.data"
            :setting="dataSetting.DriverOnline.items"
          ></DriverOnline>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.driver.show"
        v-loading="dataSetting.driver.loading"
        style="width: 66.5%"
        title="机手信息"
      >
        <template slot="main">
          <driver :data="dataSetting.driver.data"></driver>
        </template>
      </CardBox>
    </div>
    <div
      v-if="$store.state.userInfo.standingKey != 1"
      style="display: flex; justify-content: space-between"
    >
      <CardBox
        v-if="dataSetting.ServiceOnline.show"
        v-loading="dataSetting.ServiceOnline.loading"
        style="width: 33%"
        title="服务在线"
      >
        <template slot="main">
          <ServiceOnline
            :data="dataSetting.ServiceOnline.data"
            :setting="dataSetting.ServiceOnline.items"
          ></ServiceOnline>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.CaseList.show"
        v-loading="dataSetting.CaseList.loading"
        style="width: 42%"
        title="案件列表"
      >
        <template slot="main">
          <CaseList :data="dataSetting.CaseList.data"></CaseList>
        </template>
        <div slot="action" class="flex-box flex-items">
          <el-select
            v-model="dataSetting.CaseList.params.rangeFlag"
            placeholder="请选择"
            class="data-scope"
            @change="dateSectionsChange($event, 'CaseList')"
          >
            <el-option
              v-for="item in dateSections4"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <div @click="goToMore('CaseList')" class="view-more">
            <span>查看更多</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </CardBox>
      <CardBox
        v-if="dataSetting.CaseAnalysis.show"
        v-loading="dataSetting.CaseAnalysis.loading"
        style="width: 24%"
        title="案件分析"
      >
        <div slot="action">
          <el-radio-group
            class="radio-group"
            @input="dateSectionsChange($event, 'CaseAnalysis')"
            v-model="dataSetting.CaseAnalysis.params.condition"
          >
            <el-radio
              v-for="(type, index) in caseAnalyzeListTypes"
              :key="type.key"
              :label="type.key"
              border
            >
              {{ type.value }}
            </el-radio>
          </el-radio-group>
        </div>
        <template slot="main">
          <CaseAnalysis :data="dataSetting.CaseAnalysis.data"></CaseAnalysis>
        </template>
      </CardBox>
    </div>
    <div
      v-if="$store.state.userInfo.standingKey != 1"
      style="display: flex; justify-content: space-between"
    >
      <CardBox
        v-if="dataSetting.RiskControlOnline.show"
        v-loading="dataSetting.RiskControlOnline.loading"
        style="width: 33%"
        title="风控在线"
      >
        <template slot="main">
          <RiskControlOnline
            :data="dataSetting.RiskControlOnline.data"
            :setting="dataSetting.RiskControlOnline.items"
          ></RiskControlOnline>
        </template>
      </CardBox>
      <CardBox
        v-if="dataSetting.RiskControlTrain.show"
        v-loading="dataSetting.RiskControlTrain.loading"
        style="width: 66.5%"
        title="风控培训"
      >
        <div slot="action" class="flex-box flex-items">
          <el-select
            v-model="dataSetting.RiskControlTrain.params.riskUnit"
            placeholder="请选择"
            class="data-scope"
            @change="dateSectionsChange($event, 'RiskControlTrain')"
          >
            <el-option
              v-for="item in dateSections3"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <div @click="goToMore('RiskControlTrain')" class="view-more">
            <span>查看更多</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <template slot="main">
          <RiskControlTrain
            :data="dataSetting.RiskControlTrain.data"
          ></RiskControlTrain>
        </template>
      </CardBox>
    </div>
    <SetDataDrawer
      v-model="isConfiguration"
      :settingList="dataSetting"
      @itemChange="switchItemChange"
    ></SetDataDrawer>
    <!-- <RiskInterventionDialog></RiskInterventionDialog> -->
  </div>
</template>

<script>
import analysis from "./components/analysis.vue";
import manHour from "./components/manHour.vue";
import startBox from "./components/startBox.vue";
import trendAnalyze from "./components/trendAnalyze.vue";
import proportionAnalyze from "./components/proportionAnalyze.vue";
import accident from "./components/accident.vue";
import caseList from "./components/case.vue";
import risk from "./components/risk.vue";
import driver from "./components/driver.vue";
import importDialog from "./components/importDialog.vue";
import draggable from "vuedraggable";
import {
  getRiskPage,
  userProject,
  saveDataConfig,
  getDataConfig,
  getWorkSituation,
  getHourCount,
} from "@/api/SystemData";
import { getRiskTree } from "@/api/project";
import { dictionaryBatch } from "@/api/policy";
////////////////////////////////////////////////
import {
  getEquipmentOnlineData,
  getProjectCustomer,
  getWorkCondition,
  getRiskTrainOnlineData,
  getRiskData2,
  getCaseList,
  getCaseAnalyzeList,
  getWorkTimeDistribution,
  getDevAddressCode,
  getSafetyIndex,
  getAlarmRecord,
  getSafetyIndexRecord,
} from "@/api/riskData.js";

import { projectTrainingRecordPage } from "@/api/basicData";
//
import RiskInterventionDialog from "./components/RiskInterventionDialog.vue";
// import { poll } from "@/utils/sleep.js";
import { getIdentityNotShowConfig } from "../js/index.js";
import CardBox from "./components/CardBox.vue";
import ProjectIndicat from "./components/ProjectIndicat.vue";
import DriverOnline from "./components/DriverOnline.vue";
import PolicyOnline from "./components/PolicyOnline.vue";
import EquipmentOnline from "./components/EquipmentOnline.vue";
import Safety from "./components/Safety.vue";
import CaseAnalysis from "./components/CaseAnalysis.vue";
import ServiceOnline from "./components/ServiceOnline.vue";
import RiskControlOnline from "./components/RiskControlOnline.vue";
import CaseList from "./components/CaseList.vue";
import RiskControlTrain from "./components/RiskControlTrain.vue";
import RiskWaring from "./components/RiskWaring.vue";
import SetDataDrawer from "./components/SetDataDrawer.vue";
// 测试
// import YLRecorder from "@/components/Intercom/recorded.js";
//
export default {
  name: "riskHomeManagement",
  components: {
    analysis,
    manHour,
    startBox,
    trendAnalyze,
    proportionAnalyze,
    accident,
    caseList,
    risk,
    importDialog,
    driver,
    draggable,
    CardBox,
    ProjectIndicat,
    DriverOnline,
    PolicyOnline,
    EquipmentOnline,
    Safety,
    CaseAnalysis,
    ServiceOnline,
    RiskControlOnline,
    CaseList,
    RiskControlTrain,
    RiskWaring,
    SetDataDrawer,
    RiskInterventionDialog,
  },
  data() {
    return {
      intervalId: null,
      simplifySet: [],
      alarmTips: [
        {
          type: "一键报案",
          tips: "用户通过主机终端一键报案按钮上报的报警",
        },
        {
          type: "倾覆角度过大且持续时间过长",
          tips: "倾覆角度大于30度，持续时间超过10分",
        },
        {
          type: "盲区告警",
          tips: "盲区有行人并且小于2M的告警",
        },
        {
          type: "设备连续10天离线",
          tips: "机械设备设备状态连续10天离线",
        },
        {
          type: "连续3次以上夜间作业",
          tips: "晚上10点到凌晨6点期间，设备作业时间累计超过2小时以上",
        },
        {
          type: "摄像头丢失",
          tips: "机械设备没有录像数据上报",
        },
        {
          type: "天气告警",
          tips: "未来3天的极端天气",
        },
      ],
      dateSections: [
        {
          key: "W",
          value: "今周",
        },
        {
          key: "M",
          value: "今月",
        },
      ],
      dateSections2: [
        {
          key: "W",
          value: "今周",
        },
        {
          key: "M",
          value: "今月",
        },
        // {
        //   key: "Y",
        //   value: "今年",
        // },
      ],
      dateSections3: [
        {
          key: "D",
          value: "今日",
        },
        {
          key: "M",
          value: "今月",
        },
        {
          key: "Y",
          value: "今年",
        },
      ],
      dateSections4: [
        {
          key: "day",
          value: "今日",
        },
        {
          key: "month",
          value: "今月",
        },
        {
          key: "year",
          value: "今年",
        },
      ],
      dateSections5: [
        {
          key: 2,
          value: "今周",
        },
        {
          key: 1,
          value: "今月",
        },
      ],
      caseAnalyzeListTypes: [
        {
          key: "type",
          value: "按类型",
        },
        {
          key: "address",
          value: "按地区",
        },
      ],
      workOrWeatherTypes: [
        {
          key: "W",
          value: "工况情况",
        },
        {
          key: "T",
          value: "天气预测",
        },
      ],
      searchParam: {
        projectId: "",
        userId: "",
        companyName: "",
        branchName: "",
        // productLine: "",
        deviceTypeName: "",
      },
      companys: "",
      projectName: "",
      projectList: [],
      details: {},
      isImport: false,
      isConfiguration: false,
      dictList: {
        projectList: [],
        customerList: [],
        deviceTypeList: [],
      },
      projectId: "",
      configId: "",
      workSituation: [],
      hourSituation: {},
      timer: null, // 时间定时器
      projectSelectTree: [],
      projectProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          if (level == 0) {
            dictionaryBatch({
              codes: "insuranceCompany",
            }).then((res) => {
              if (res && res.data) {
                const { insuranceCompany } = res.data;
                resolve(
                  insuranceCompany.map((val) => {
                    return {
                      value: val.dictValue,
                      label: val.dictValue,
                      remark: val.remark,
                      leaf: false,
                      emitPath: false,
                    };
                  })
                );
              } else {
                resolve([]);
              }
            });
          } else {
            node.data.remark
              ? resolve(
                  node.data.remark.split(",").map((val) => {
                    return {
                      value: val,
                      label: val,
                      emitPath: false,
                      leaf: true,
                    };
                  })
                )
              : resolve([]);
          }
        },
      },
      temporaryData: {},
      interfaceData: {},
      projectLines: [],
      gradeShow: false,
      // 项目指标、保单在线、服务在线 为同一个接口获取数据
      dataSetting: {
        ProjectIndicat: {
          title: "项目指标",
          show: true,
          loading: false,
          itemDrawShow: true,
          dataFun: this.handleRisk2MoreData,
          params: {
            key: "ProjectIndicat",
            datakey: "projectIndex",
          },
          data: {},
          items: [
            {
              title: "签约赔付率",
              data: "0",
              unit: "%",
              // icon: require("@/assets/images/RiskControl/mbbf.png"),
              show: true,
              key: "signRate",
              isChart: true,
              isNone: false,
              noneKey: "signCompensateRateDisplay",
              chartStyle: {
                backgroundColor: "#60A6FF",
                backgroundColor2: "#ABCEFD",
              },
            },
            {
              title: "目标赔付率",
              data: "0",
              unit: "%",
              show: true,
              key: "targetRate",
              isChart: true,
              isNone: false,
              noneKey: "targetCompensateDisplay",
              chartStyle: {
                backgroundColor: "#4DC073",
                backgroundColor2: "#ABE1BF",
              },
            },
            {
              title: "签约管控率",
              data: "0",
              unit: "%",
              // icon: require("@/assets/images/RiskControl/bfl.png"),
              show: true,
              key: "signCtrlRate",
              isChart: true,
              isNone: false,
              noneKey: "signCtrlRateDisplay",
              chartStyle: {
                backgroundColor: "#60A6FF",
                backgroundColor2: "#ABCEFD",
              },
            },
            {
              title: "目标管控率",
              data: "0",
              unit: "%",
              show: true,
              key: "targetCtrlRate",
              isChart: true,
              isNone: false,
              noneKey: "targetCtrlRateDisplay",
              chartStyle: {
                backgroundColor: "#4DC073",
                backgroundColor2: "#ABE1BF",
              },
            },
            {
              title: "签单保费",
              data: "0",
              unit: "",
              icon: require("@/assets/images/RiskControl/qybf.png"),
              show: true,
              isNone: false,
              noneKey: "signPremiumsDisplay",
              key: "orderAmount",
            },
            {
              title: "已赚保费",
              data: "0",
              unit: "",
              icon: require("@/assets/images/RiskControl/mbbf.png"),
              show: true,
              isChart: false,
              key: "profitAmount",
              // chartStyle: {
              //   backgroundColor: "#4DC073",
              //   backgroundColor2: "#ABE1BF",
              // },
            },
            {
              title: "满期赔付率",
              data: "0",
              unit: "%",
              show: true,
              key: "orderRate",
              isChart: true,
              compareKeySet: {
                key: "targetCtrlRate",
                style: {
                  background: "#FEECE9",
                  color: "#F64A2D",
                },
              },
              chartStyle: {
                backgroundColor: "#F27B00",
                backgroundColor2: "#EFCEAB",
              },
            },
            // {
            //   title: "已报告赔付率",
            //   data: "0",
            //   unit: "%",
            //   // icon: require("@/assets/images/RiskControl/qybf.png"),
            //   show: true,
            //   isChart: true,
            //   key: "hasCompensateRate",
            //   chartStyle: {
            //     backgroundColor: "rgba(108, 108, 249, 1)",
            //     backgroundColor2: "#E9E9FF",
            //   },
            // },
            {
              title: "管控率",
              data: "0",
              unit: "%",
              show: true,
              key: "controlRate",
              compareKeySet: {
                key: "targetCtrlRate",
                style: {
                  background: "#FEECE9",
                  color: "#F64A2D",
                },
              },
              isChart: true,
              chartStyle: {
                backgroundColor: "#F96CB6",
                backgroundColor2: "#FFEBF5",
              },
            },
          ],
        },
        Safety: {
          title: "安全指数",
          show: true,
          loading: false,
          innerLoading: false,
          itemDrawShow: false,
          dataFun: this.handleSafetyIndex,
          data: {
            faceData: {},
            innerData: {},
          },
        },
        PolicyOnline: {
          title: "保单在线",
          show: true,
          loading: false,
          itemDrawShow: true,
          dataFun: this.handleRisk2MoreData,
          params: {
            key: "PolicyOnline",
            datakey: "policyOnLine",
          },
          data: {},
          objItems: {
            detailList: {
              title: "保费趋势分析",
              show: true,
            },
          },
          items: [
            {
              show: true,
              title: "保单总数(张)",
              data: 0,
              textc: "#333333",
              bgc: "linear-gradient( 163deg, #EAEDF2 0%, #FAFBFC 100%)",
              icon: require("@/assets/images/RiskControl/bdzs.png"),
              key: "policyCount",
            },
            {
              show: true,
              title: "单均保费(元)",
              data: 0,
              textc: "#333333",
              bgc: "linear-gradient( 160deg, #FEF4E5 0%, #FFFDF8 100%)",
              icon: require("@/assets/images/RiskControl/djbf.png"),
              key: "averagePremium",
            },
            {
              show: true,
              title: "保障设备(台)",
              data: 0,
              textc: "#0D2A6E",
              bgc: "linear-gradient( 163deg, #E4ECFE 0%, #F8FBFE 100%)",
              icon: require("@/assets/images/RiskControl/bzsb.png"),
              key: "deviceCount",
            },
            {
              show: true,
              title: "待续保(张)",
              data: 0,
              textc: "#01445E",
              bgc: "linear-gradient( 160deg, #E1F3FF 0%, #F8FDFF 100%)",
              icon: require("@/assets/images/RiskControl/dxb.png"),
              key: "renewalCount",
            },
          ],
        },
        EquipmentOnline: {
          title: "设备在线",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.handleEquipmentOnlineData,
          data: {},
          params: {
            runnerUnit: "M",
          },
          data: {},
        },
        RiskWaring: {
          title: "风险告警",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.handleRiskWaring,
          subpage: "/riskHomeManagement/alarmInterveneRecord",
          data: [],
          params: {
            dateType: 2,
          },
        },
        startBox: {
          title: "工况情况",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.getWeatherOrWorkData,
          params: {
            // T 天气 W 工况
            weatherOrWork: "W",
            runnerUnit: "W",
          },
          data: {
            type: "W",
            list: [],
          },
        },
        manHour: {
          title: "工时分布",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.handleManHour,
          subpage: "/riskHomeManagement/riskWorkTimes",
          data: {},
          params: {
            runnerUnit: "M",
          },
        },
        DriverOnline: {
          title: "机手在线",
          show: true,
          loading: false,
          itemDrawShow: true,
          dataFun: this.handleRisk2MoreData,
          data: {},
          params: {
            key: "DriverOnline",
            datakey: "jiShou",
          },
          items: [
            {
              show: true,
              title: "机手总数(人)",
              data: 0,
              icon: require("@/assets/images/RiskControl/ijszs.png"),
              img: require("@/assets/images/RiskControl/jszs.png"),
              bgc: "rgba(32, 203, 187, 0.1)",
              key: "jsCount",
            },
            {
              show: true,
              title: "持证机手(人)",
              data: 0,
              icon: require("@/assets/images/RiskControl/iczjs.png"),
              img: require("@/assets/images/RiskControl/czjs.png"),
              bgc: "rgba(240, 119, 53, 0.1)",
              key: "jsCZCount",
            },
            {
              show: true,
              title: "出险机手(人)",
              data: 0,
              icon: require("@/assets/images/RiskControl/icsjs.png"),
              img: require("@/assets/images/RiskControl/csjs.png"),
              bgc: "rgba(0, 175, 255, 0.1)",
              key: "jsOutCount",
            },
            {
              show: true,
              title: "参训机手(人)",
              data: 0,
              icon: require("@/assets/images/RiskControl/icxjs.png"),
              img: require("@/assets/images/RiskControl/cxjs.png"),
              bgc: "rgba(62, 81, 255, 0.1)",
              key: "jsCXCount",
            },
          ],
        },
        driver: {
          title: "机手信息",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.handleRisk2MoreData,
          data: {},
          params: {
            key: "driver",
            datakey: "jiShou",
          },
        },
        ServiceOnline: {
          title: "服务在线",
          show: true,
          loading: false,
          itemDrawShow: true,
          dataFun: this.handleRisk2MoreData,
          params: {
            key: "ServiceOnline",
            datakey: "serviceOnLine",
          },
          data: {},
          items: [
            {
              show: true,
              title: "案件总数(个)",
              data: 0,
              icon: require("@/assets/images/RiskControl/ajzs.png"),
              key: "caseCount",
            },
            {
              show: true,
              title: "出险总金额(元)",
              data: 0,
              icon: require("@/assets/images/RiskControl/cxzje.png"),
              key: "riskTotalAmount",
            },
            {
              show: true,
              title: "已结案件赔付金额(元)",
              data: 0,
              icon: require("@/assets/images/RiskControl/ypfje.png"),
              key: "amountPayable",
            },
            {
              show: true,
              title: "未结案件预估金额(元)",
              data: 0,
              icon: require("@/assets/images/RiskControl/wpfje.png"),
              key: "notRiskAmount",
            },
            {
              show: true,
              title: "0赔付案件(个)",
              data: 0,
              icon: require("@/assets/images/RiskControl/0pfaj.png"),
              key: "zeroCompensateCount",
            },
            {
              show: true,
              title: "0赔付案件预估金额(元)",
              data: 0,
              icon: require("@/assets/images/RiskControl/0pfje.png"),
              key: "zeroRiskAmount",
            },
          ],
        },
        CaseList: {
          title: "案件列表",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.handleCaseList,
          subpage: "/riskHomeManagement/caseList",
          params: {
            rangeFlag: "year",
          },
        },
        CaseAnalysis: {
          title: "案件分析",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.handleCaseAnalyzeList,
          data: {
            type: "type",
            list: [],
          },
          params: {
            condition: "type",
          },
        },
        RiskControlOnline: {
          title: "风控在线",
          show: true,
          loading: false,
          itemDrawShow: true,
          data: {},
          items: [
            {
              show: true,
              title: "风险管控天数",
              data: 0,
              icon: require("@/assets/images/RiskControl/fxgkts.png"),
              key: "dayCount",
            },
            {
              show: true,
              title: "连续无事故天数",
              data: 0,
              icon: require("@/assets/images/RiskControl/lxwsgts.png"),
              key: "successionDayCount",
            },
            {
              show: true,
              title: "风控培训次数",
              data: 0,
              icon: require("@/assets/images/RiskControl/fkpxcs.png"),
              key: "preventCount",
            },
            {
              show: true,
              title: "培训总人数",
              data: 0,
              icon: require("@/assets/images/RiskControl/pxzrs.png"),
              key: "trainCount",
            },
            {
              show: true,
              title: "告警干预次数",
              data: 0,
              icon: require("@/assets/images/RiskControl/gjgycs.png"),
              key: "alarmInterventionCount",
            },
            {
              show: true,
              title: "客服干预次数",
              data: 0,
              icon: require("@/assets/images/RiskControl/kfgycs.png"),
              key: "interveneCount",
            },
          ],
        },
        RiskControlTrain: {
          title: "风控培训",
          show: true,
          loading: false,
          itemDrawShow: false,
          dataFun: this.handleRiskTrainOnlineData,
          subpage: "/riskHomeManagement/riskTrainList",
          params: {
            riskUnit: "Y",
          },
          data: [],
        },
      },
    };
  },
  watch: {
    companys: {
      handler(e) {
        if (!e || !e.length) {
          this.searchParam.companyName = "";
          this.searchParam.branchName = "";
        }
      },
    },
    gradeShow(n) {
      if (n) {
        this.handleSafetyIndexInner(this.searchParam);
      } else {
        this.dataSetting.Safety.innerLoading = false;
      }
    },
  },
  async mounted() {
    await this.getUserProject();
    this.getDictionaryBatch();
    this.getConfig();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    //销毁定时器
  },
  methods: {
    dataInit(mod = "") {
      if (this.judgeParams()) return;
      if (mod) {
        this.getModData(mod);
      } else {
        Object.keys(this.dataSetting).forEach((key) => {
          this.getModData(key);
        });
      }
    },
    getModData(mod = "") {
      if (
        mod &&
        Reflect.has(this.dataSetting, mod) &&
        this.dataSetting[mod].show &&
        this.dataSetting[mod].dataFun
      ) {
        let params = this.searchParam;
        if (this.dataSetting[mod].params) {
          params = { ...params, ...this.dataSetting[mod].params };
        }
        this.dataSetting[mod].loading = true;
        this.dataSetting[mod].dataFun(params);
      }
    },
    getConfig(def = false) {
      if (def) {
        this.setShowDrawer(this.simplifySet);
      } else {
        getDataConfig({ userId: this.$store.state.userInfo.MJUserId }).then(
          (res) => {
            res && res.data && res.data.contentJson ? this.setShowDrawer(res.data.contentJson) : 
      (!this.judgeParams() ? this.dataInit() : '');
          }
        );
      }
    },
    // 判断必要条件
    judgeParams() {
      if (!this.searchParam.projectId) {
        this.$message({
          message: "请选择项目！",
        });
        return true;
      }
    },
    setShowDrawer(sett = {}) {
      this.simplifySet = JSON.parse(sett);
      let blockSetting = getIdentityNotShowConfig(
        this.$store.state.userInfo.standingKey
      );
      Object.keys(this.dataSetting).forEach((key) => {
        this.dataSetting[key].show = this.simplifySet[key]?.show || false;
        //this.dataSetting[key].itemDrawShow = simplifySet[key].itemDrawShow;
        if (this.dataSetting[key].items) {
          // 按照key进行对应设置
          this.dataSetting[key].items.forEach((item, index) => {
            this.simplifySet[key].items.forEach((bitem) => {
              if (item.key == bitem.key) {
                item.show = bitem.show;
              }
            });
          });
        }
        if (this.dataSetting[key].objItems) {
          Object.keys(this.dataSetting[key].objItems).forEach((okey) => {
            this.dataSetting[key].objItems[okey].show =
              this.simplifySet[key].objItems[okey].show;
          });
        }
        // 根据身份屏蔽数据项
        if (Reflect.has(blockSetting, key)) {
          //当前只对items进行屏蔽
          this.dataSetting[key].items.forEach((item, index) => {
            blockSetting[key].items.forEach((bitem) => {
              if (item.key == bitem.key) {
                item.show = false;
                item.block = true;
              }
            });
          });
        }
      });
      if (this.judgeParams()) return;
      this.dataInit();
    },
    handleProjectSelect(data) {
      this.searchParam.userId = "";
      this.getCustomerList();
    },
    handleCompanySelect(e) {
      let keys = ["companyName", "branchName"];
      e.forEach((place, pi) => {
        this.searchParam[keys[pi]] = place;
      });
    },
    handleReset() {
      Object.keys(this.searchParam).forEach((key) => {
        this.searchParam[key] = "";
        this.companys = [];
        // if (key == "") {
        //   this.searchParam[key] = [];
        // }
      });
      this.searchParam.projectId = this.dictList.projectList[0].dictKey;
      this.$forceUpdate();
      this.dataInit();
    },
    handleAnew() {
      let e = this.dictList.projectList.find(project => project.dictKey == this.searchParam.projectId);
      console.log(e);
      this.dataSetting.ProjectIndicat.items.map(val=>{
        val.isNone !== undefined ? val.isNone = e[val.noneKey] ? false : true : '';
      });
      this.dataInit();
    },
    getDictionaryBatch() {
      dictionaryBatch({
        codes: "vehicleType,CXvehicleType",
      }).then((res) => {
        let vehicleType = res.data.vehicleType.map((item) => {
          return item.dictValue;
        });
        let CXvehicleType = res.data.CXvehicleType.map((item) => {
          return item.dictValue;
        });
        let dicty = Array.from(new Set([...vehicleType, ...CXvehicleType]));
        this.dictList.deviceTypeList = dicty.map((item) => {
          return {
            dictKey: item,
            dictValue: item,
          };
        });
      });
    },
    async getUserProject() {
      let res = await userProject({
        userId: this.$store.state.userInfo.MJUserId,
      });
      if (res.data && res.data.length > 0) {
        //this.projectList = res.data;
        this.dictList.projectList = res.data.map((item) => {
          return {
            dictKey: item.projectId,
            dictValue: item.projectName,
            ...item,
          };
        });
        this.searchParam.projectId = res.data[0].projectId;
        let e = this.dictList.projectList[0];
        this.dataSetting.ProjectIndicat.items.map(val=>{
          val.isNone !== undefined ? val.isNone = e[val.noneKey] ? false : true : '';
        });
        this.getCustomerList();
      }
    },
    getCustomerList() {
      getProjectCustomer({ projectId: this.searchParam.projectId }).then(
        (res) => {
          if (res.data.records) {
            this.dictList.customerList = res.data.records.map((item) => {
              return {
                dictKey: item.customerUserId,
                dictValue: item.customerName,
              };
            });
          } else {
            this.dictList.customerList = [];
          }
        }
      );
    },
    dateSectionsChange(e, t) {
      this.dataInit(t);
    },
    switchItemChange(t) {
      this.dataInit(t);
    },
    getWeatherOrWorkData(params) {
      this.dataSetting.startBox.data.type = params.weatherOrWork;
      if (params.weatherOrWork === "T") {
        getDevAddressCode(params).then(
          (res) => {
            this.dataSetting.startBox.loading = false;
            this.dataSetting.startBox.data.list = res.data;
          },
          (rej) => {
            this.dataSetting.startBox.data.list = [];
          }
        );
      } else {
        getWorkCondition(params).then(
          (res) => {
            this.dataSetting.startBox.loading = false;
            this.dataSetting.startBox.data.list = res.data;
          },
          (rej) => {
            this.dataSetting.startBox.data.list = [];
          }
        );
      }
    },
    handleEquipmentOnlineData(params) {
      getEquipmentOnlineData(params).then(
        (res) => {
          this.dataSetting.EquipmentOnline.loading = false;
          this.dataSetting.EquipmentOnline.data = res.data;
        },
        (rej) => {
          this.dataSetting.EquipmentOnline.data = [];
        }
      );
    },
    handleRiskTrainOnlineData(params) {
      getRiskTrainOnlineData(params)
        .then(
          (res) => {
            this.dataSetting.RiskControlTrain.loading = false;
            this.dataSetting.RiskControlOnline.data = res.data;
            this.dataSetting.RiskControlTrain.data = res.data.projectTrainingList;
          },
          (rej) => {
            this.dataSetting.RiskControlOnline.data = {};
            this.dataSetting.RiskControlTrain.data = [];
          }
        )
        .finally(() => {
          this.$forceUpdate();
        });
      // params.index = 1;
      // params.size = 99999; 
      // projectTrainingRecordPage(params)
      //   .then(
      //     (res) => {
      //       this.dataSetting.RiskControlTrain.loading = false;
      //       this.dataSetting.RiskControlTrain.data = res.data.records || [];
      //     },
      //     (rej) => {
      //       this.dataSetting.RiskControlTrain.data = [];
      //     }
      //   )
      //   .finally(() => {
      //     this.$forceUpdate();
      //   });
    },
    handleRisk2MoreData(params) {
      getRiskData2(params).then(
        (res) => {
          this.dataSetting[params.key].data = res.data
            ? res.data[params.datakey] || {}
            : {};
          this.dataSetting[params.key].loading = false;
        },
        (rej) => {
          this.dataSetting[params.key].data = {};
        }
      );
    },
    handleCaseList(params) {
      getCaseList(params)
        .then(
          (res) => {
            this.dataSetting.CaseList.loading = false;
            this.dataSetting.CaseList.data = res?.data?.records || [];
          },
          (rej) => {
            this.dataSetting.CaseList.data = [];
          }
        )
        .finally(() => {
          this.$forceUpdate();
        });
    },
    handleCaseAnalyzeList(params) {
      getCaseAnalyzeList(params).then(
        (res) => {
          this.dataSetting.CaseAnalysis.loading = false;
          this.dataSetting.CaseAnalysis.data = {
            type: this.dataSetting.CaseAnalysis.params.condition,
            list: res.data || [],
          };
        },
        (rej) => {
          this.dataSetting.CaseAnalysis.data = {
            type: this.dataSetting.CaseAnalysis.params.condition,
            list: [],
          };
        }
      );
    },
    handleManHour(params) {
      getWorkTimeDistribution(params).then(
        (res) => {
          this.dataSetting.manHour.loading = false;
          this.dataSetting.manHour.data = res?.data || {};
        },
        (rej) => {
          this.dataSetting.manHour.data = {};
        }
      );
    },
    handleSafetyIndex(params) {
      getSafetyIndex(params).then(
        (res) => {
          this.dataSetting.Safety.loading = false;
          this.dataSetting.Safety.data.faceData = res?.data || {};
        },
        (rej) => {
          this.dataSetting.Safety.data.faceData = {};
        }
      );
      // getSafetyIndexRecord(params).then(
      //   (res) => {
      //     this.dataSetting.Safety.data.innerData = res?.data || {};
      //   },
      //   (rej) => {
      //     this.dataSetting.Safety.data.innerData = {};
      //   }
      // );
    },
    handleSafetyIndexInner(params) {
      this.dataSetting.Safety.innerLoading = true;
      getSafetyIndexRecord(params).then(
        (res) => {
          this.dataSetting.Safety.data.innerData = res?.data || {};
          this.dataSetting.Safety.innerLoading = false;
        },
        (rej) => {
          this.dataSetting.Safety.data.innerData = {};
          this.dataSetting.Safety.innerLoading = false;
        }
      );
    },
    handleRiskWaring(params) {
      getAlarmRecord(params).then(
        (res) => {
          this.dataSetting.RiskWaring.loading = false;
          this.dataSetting.RiskWaring.data = res.data || [];
        },
        (rej) => {}
      );
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        this.dataSetting.RiskWaring.loading = true;
        getAlarmRecord(params).then(
          (res) => {
            this.dataSetting.RiskWaring.loading = false;
            this.dataSetting.RiskWaring.data = res.data || [];
          },
          (rej) => {}
        );
      }, 10000);
    },
    goToMore(type) {
      if (type && this.dataSetting[type].subpage) {
        this.$router.push({
          path: this.dataSetting[type].subpage,
          query: type == 'CaseList' ? { 
            projectId: this.searchParam.projectId,
            customerId: this.searchParam.userId,
            companyName: this.searchParam.deviceTypeName,
            branchName: this.searchParam.deviceTypeName,
            // productLine: "",
            deviceTypeName: this.searchParam.deviceTypeName,
          } : this.searchParam,
        });
      }
    },
    //TODO 测试录音
    // handleTest() {
    //   const recorder = new YLRecorder();
    //   recorder.startRecording();
    //   setTimeout(() => {
    //     recorder.stopRecording();
    //   }, 3000);
    //   setTimeout(() => {
    //     recorder.download();
    //   }, 5000);
    // },
  },
};
</script>

<style lang="scss">
.risk-decrement-form-cascader {
  .el-scrollbar__wrap {
    max-height: 600px;
  }
}
.al-tool-tips {
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #f5f5f5 !important;
}
</style>
<style lang="scss" scoped>
::v-deep .el-table td.el-table__cell {
  border-bottom: none;
}
::v-deep .el-table__header {
  .cell {
    font-weight: bold;
    color: #333;
  }
}
::v-deep .el-table::before {
  display: none;
}
::v-deep .el-table__row {
  .cell {
    color: #333;
  }
}
.el-form-item--small {
  margin: unset;
}
.risk-decrement {
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  &-project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    position: sticky;
    top: 0;
    box-shadow: 0px 0px 10px 10px rgba(246, 248, 249, 0.26);
    &-left {
      display: flex;
      align-items: center;
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.flex-items {
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-12 {
  flex: 12;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.blue-btn {
  background: #4278c9;
  border: 1px solid #4278c9;
  border-radius: 4px 4px 4px 4px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.white-btn {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px 4px 4px 4px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.white-blue-btn {
  background: #ffffff;
  border: 1px solid #4278c9;
  border-radius: 4px 4px 4px 4px;
  font-weight: 400;
  font-size: 14px;
  color: #4278c9;
}
.data-scope {
  width: 80px;
}
.view-more {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-left: 24px;
  cursor: pointer;
}
.top-action-btn {
  width: 92px;
  height: 32px;
  background: #f2f2f2;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alarmTips {
  &-item {
    margin-bottom: 10px;
    font-size: 12px;
    &-type {
      color: #333333;
    }
    &-tips {
      color: #999999;
    }
  }
}
.Safety-record {
  font-weight: 400;
  font-size: 14px;
  color: #3470ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.radio-group {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    padding-left: 0px;
  }
  ::v-deep .el-radio {
    color: #4278c9;
    margin-right: 0px;
  }
  ::v-deep .is-bordered {
    border: 1px solid #4278c9;
    padding: 8px 10px 0 10px;
  }
  ::v-deep .is-checked {
    background-color: #4278c9;
  }
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #ffffff;
    margin-left: 0px;
  }
}
</style>
