<template>
  <div class="mian-container">
    <div
      class="item"
      v-for="(item, index) in setting"
      v-show="item.show"
      :key="index"
    >
      <div class="img">
        <img :src="item.icon" style="width: 35px; height: 35px" />
      </div>
      <div class="right">
        <div class="title">{{ item.title }}</div>
        <div class="data">{{ data[item.key] || 0 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RiskControlOnline",
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    setting: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      itemList: [
        {
          title: "风险管控天数",
          data: 0,
          icon: require("@/assets/images/RiskControl/fxgkts.png"),
          key: "dayCount",
        },
        {
          title: "连续无事故天数",
          data: 0,
          icon: require("@/assets/images/RiskControl/lxwsgts.png"),
          key: "successionDayCount",
        },
        {
          title: "风控培训次数",
          data: 0,
          icon: require("@/assets/images/RiskControl/fkpxcs.png"),
          key: "preventCount",
        },
        {
          title: "培训总人数",
          data: 0,
          icon: require("@/assets/images/RiskControl/pxzrs.png"),
          key: "trainCount",
        },
        {
          title: "告警干预次数",
          data: 0,
          icon: require("@/assets/images/RiskControl/gjgycs.png"),
          key: "alarmInterventionCount",
        },
        {
          title: "客服干预次数",
          data: 0,
          icon: require("@/assets/images/RiskControl/kfgycs.png"),
          key: "interveneCount",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.mian-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
  .item {
    display: flex;
    align-items: center;
    height: 55px;
    width: 260px;
    margin-top: 40px;
    margin-left: 24px;
    .img {
      width: 55px;
      height: 55px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 1px rgba(16, 197, 180, 0.09);
      border-radius: 4px 4px 4px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
      .data {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
        margin-top: 5px;
      }
    }
  }
}
</style>
