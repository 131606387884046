<template>
  <div>
    <!-- 按类型 -->
    <el-table
      v-show="data.type === 'type'"
      :data="tableData"
      :span-method="objectSpanMethod"
      :cell-class-name="tableRowClassName"
      height="300"
      style="width: 100%; margin-top: 20px"
    >
      <template slot="empty">
        <el-empty
          description="列表暂无数据"
          :image="require('@/assets/images/RiskControl/risk-empty.png')"
        ></el-empty>
      </template>
      <el-table-column width="50" align="center">
        <div
          class="tag"
          slot-scope="{ row, column, $index }"
          :style="{
            color: colours[($index / 2) % 5].tc,
            backgroundColor: colours[($index / 2) % 5].bc,
          }"
        >
          {{ $index / 2 + 1 }}
        </div>
      </el-table-column>
      <el-table-column prop="dangerType" label="案件类型" align="left">
        <div slot-scope="{ row, column, $index }" v-if="$index % 2 === 0">
          {{ row.dangerType || "---" }}
        </div>
        <div v-else>
          <el-progress
            :show-text="false"
            :stroke-width="14"
            :percentage="row.dangerType"
          ></el-progress>
        </div>
      </el-table-column>
      <el-table-column prop="amountPayable" label="赔付金额(元)" align="center">
        <span slot-scope="{ row, column, $index }" v-if="$index % 2 === 0">
          {{ addThousandSeparator(row.amountPayable) || 0 }}
        </span>
        <span class="rate" slot-scope="{ row, column, $index }" v-else
          >{{ row.dangerType || "---" }}%</span
        >
      </el-table-column>
      <el-table-column prop="dangerCount" label="出险次数" align="center">
      </el-table-column>
      <el-table-column
        prop="dangerCountPercent"
        label="次数占比"
        align="center"
      >
      </el-table-column>
    </el-table>
    <!-- 按地区 -->
    <el-table
      v-show="data.type === 'address'"
      :data="tableData"
      :span-method="objectSpanMethod2"
      :cell-class-name="tableRowClassName"
      style="width: 100%; margin-top: 20px"
      height="300"
    >
      <template slot="empty">
        <el-empty
          description="列表暂无数据"
          :image="require('@/assets/images/RiskControl/risk-empty.png')"
        ></el-empty>
      </template>
      <el-table-column width="50" align="center">
        <div
          class="tag"
          slot-scope="{ row, column, $index }"
          :style="{
            color: colours[($index / 2) % 5].tc,
            backgroundColor: colours[($index / 2) % 5].bc,
          }"
        >
          {{ $index / 2 + 1 }}
        </div>
      </el-table-column>
      <el-table-column prop="dangerProvince" label="案件地区" align="left">
        <div slot-scope="{ row, column, $index }" v-if="$index % 2 === 0">
          {{ row.dangerProvince || row.dangerAddr || "---" }}
        </div>
        <div v-else>
          <el-progress
            :show-text="false"
            :stroke-width="14"
            :percentage="row.dangerProvince || row.dangerAddr"
          ></el-progress>
        </div>
      </el-table-column>
      <el-table-column prop="deviceCount" label="设备总数" align="center">
        <span slot-scope="{ row, column, $index }" v-if="$index % 2 === 0">
          {{ row.deviceCount }}
        </span>
        <span class="rate" slot-scope="{ row, column, $index }" v-else
          >{{ row.deviceCount || "---" }}%</span
        >
      </el-table-column>
      <el-table-column prop="amountPayable" label="赔付金额" align="center">
        <span slot-scope="{ row, column, $index }">
          {{ addThousandSeparator(row.amountPayable) || 0 }}
        </span>
      </el-table-column>
      <el-table-column prop="dangerCount" label="出险次数" align="center">
      </el-table-column>
      <el-table-column
        prop="dangerCountPercent"
        label="次数占比"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { addThousandSeparator } from "@/utils/dealNumber.js";
export default {
  name: "CaseAnalysis",
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return {
          list: [],
          type: "type",
        };
      },
    },
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        let singles = [];
        let combine = [];
        if (newValue.type === "type") {
          newValue.list.forEach((item) => {
            singles.push({
              one: { ...item, ...{ dangerCountPercent: "" } },
              two: {
                dangerType: item.dangerCountPercent,
                amountPayable: item.dangerCountPercent,
              },
            });
          });
        } else {
          newValue.list.forEach((item) => {
            singles.push({
              one: { ...item, ...{ dangerCountPercent: "" } },
              two: {
                dangerProvince: item.dangerCountPercent,
                deviceCount: item.dangerCountPercent,
              },
            });
          });
        }
        singles.forEach((item) => {
          combine.push(item.one);
          combine.push(item.two);
        });
        this.tableData = combine;
        this.$forceUpdate();
      },
    },
  },
  data() {
    return {
      addThousandSeparator: addThousandSeparator,
      tableData: [],
      colours: [
        {
          tc: "#F64A2D",
          bc: "#FEECE9",
        },
        {
          tc: "#FCA118",
          bc: "#FFF5E7",
        },
        {
          tc: "#FCCE18",
          bc: "#FFFAE7",
        },
        {
          tc: "#333333",
          bc: "#ECF5FF",
        },
        {
          tc: "#333333",
          bc: "#ECF5FF",
        },
      ],
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "zdy-row1";
      } else {
        return "zdy-row2";
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else {
        if (rowIndex % 2 !== 0) {
          return {
            rowspan: 1,
            colspan: 3,
          };
        }
      }
    },
    objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else {
        if (rowIndex % 2 !== 0) {
          return {
            rowspan: 1,
            colspan: 4,
          };
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tag {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}
.rate {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
// ::v-deep .el-table::before {
//   display: none;
// }
::v-deep .zdy-row1 {
  padding-bottom: 0;
  font-size: 14px;
}
::v-deep .zdy-row2 {
  padding-top: 0;
  font-size: 14px;
}
</style>
