<template>
  <div class="mian-container">
    <!-- 上方统计卡片 -->
    <div class="total">
      <div
        class="total-item"
        :style="{ background: item.bgc, color: item.textc }"
        v-for="(item, index) in setting"
        :key="index"
        v-show="item.show"
      >
        <div class="title">
          {{ item.title }}
        </div>
        <div class="data">
          {{ addThousandSeparator(data[item.key] || 0) }}
        </div>
        <div class="img">
          <img style="width: 70px; height: 70px" :src="item.icon" />
        </div>
      </div>
    </div>
    <div v-show="objSetting.detailList.show" class="chart-title">
      保费趋势分析
    </div>
    <!-- 折线图 -->
    <div v-show="objSetting.detailList.show" class="chart">
      <Chart
        style="width: max-content; height: max-content"
        :option="chartOption"
      ></Chart>
    </div>
  </div>
</template>

<script>
import { addThousandSeparator } from "@/utils/dealNumber.js";
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
export default {
  name: "PolicyOnline",
  components: { Chart },
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    setting: {
      type: [Object, Array],
      default: () => [],
    },
    objSetting: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      addThousandSeparator: addThousandSeparator,
      itemList: [
        {
          title: "保单总数(张)",
          data: 0,
          textc: "#333333",
          bgc: "linear-gradient( 163deg, #EAEDF2 0%, #FAFBFC 100%)",
          icon: require("@/assets/images/RiskControl/bdzs.png"),
          key: "policyCount",
        },
        {
          title: "单均保费(元)",
          data: 0,
          textc: "#333333",
          bgc: "linear-gradient( 163deg, #EAEDF2 0%, #FAFBFC 100%)",
          icon: require("@/assets/images/RiskControl/bdzs.png"),
          key: "averagePremium",
        },
        {
          title: "保障设备(台)",
          data: 0,
          textc: "#0D2A6E",
          bgc: "linear-gradient( 163deg, #E4ECFE 0%, #F8FBFE 100%)",
          icon: require("@/assets/images/RiskControl/bzsb.png"),
          key: "deviceCount",
        },
        {
          title: "待续保(张)",
          data: 0,
          textc: "#01445E",
          bgc: "linear-gradient( 160deg, #E1F3FF 0%, #F8FDFF 100%)",
          icon: require("@/assets/images/RiskControl/dxb.png"),
          key: "renewalCount",
        },
      ],
      chartOption: {},
    };
  },
  mounted() {
    this.setChartOption();
  },
  watch: {
    data(newValue, oldValue) {
      this.setChartOption();
    },
    "objSetting.detailList.show": {
      handler(newValue, oldValue) {
        if (newValue) {
          this.setChartOption();
        }
      },
    },
  },
  methods: {
    setChartOption() {
      let xLabel = [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ];
      let valueData = [];
      if (this.data?.detailList?.length) {
        this.data.detailList.forEach((item) => {
          valueData.push(item.amount);
        });
      } else {
        valueData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      }

      this.chartOption = {
        tooltip: {
          textStyle: {
            color: "#000",
          },
          padding: [10, 10],
          trigger: "axis",
          backgroundColor: "#fff",
          borderColor: "rgba(112, 119, 242, 0.8)",
          borderWidth: 1,
          axisPointer: {
            lineStyle: {
              color: "RGBA(105, 136, 217, 1)",
            },
          },
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "3%",
          top: "13%",
          containLabel: true,
        },
        toolbox: {
          show: false,
        },
        xAxis: {
          name: "月份",
          type: "category",
          data: xLabel,
          axisLine: {
            lineStyle: {
              color: "#cccccc",
            },
          },
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)",
            },
          },
        },
        yAxis: [
          {
            name: "保费/万元",
            type: "value",
            //minInterval: 50,
            axisLabel: {
              textStyle: {
                color: "rgba(0, 0, 0, 1)",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#cdd5e2",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#f5f5f5",
              },
            },
          },
        ],
        series: [
          {
            name: "保费/万元",
            type: "line",
            data: valueData,
            symbolSize: 10,
            symbol: "circle",
            showSymbol: false,
            smooth: true,
            yAxisIndex: 0,
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#0069FF",
                },
                {
                  offset: 1,
                  color: "#0069FF",
                },
              ]),
              shadowColor: "RGBA(96, 122, 209, 0.4)",
              shadowBlur: 10,
              shadowOffsetY: 10,
            },
            itemStyle: {
              normal: {
                color: "rgba(167, 181, 230, 1)",
                borderColor: "#fff",
                borderWidth: 3,
                shadowColor: "rgba(167, 181, 230, 0.7)",
                shadowBlur: 5,
              },
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "RGBA(184, 204, 241, 1)",
                    },
                    {
                      offset: 0.5,
                      color: "RGBA(184, 204, 241, 0.5)",
                    },
                    {
                      offset: 0.7,
                      color: "RGBA(184, 204, 241, 0)",
                    },
                  ],
                  false
                ),
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mian-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  .total {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-around;
    &-item {
      // width: 190px;
      width: 48%;
      height: 90px;
      border-radius: 10px 10px 10px 10px;
      padding: 16px;
      //margin-left: 15px;
      margin-top: 16px;
      position: relative;
      box-sizing: border-box;
      .title {
        font-weight: 400;
        font-size: 14px;
      }
      .data {
        font-weight: bold;
        font-size: 24px;
        margin-top: 8px;
      }
      .img {
        width: 70px;
        height: 70px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .chart {
    width: 100%;
    height: 300px;
  }
  .chart-title {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    margin-left: 15px;
    margin-top: 16px;
  }
}
</style>
