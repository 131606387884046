<template>
  <div class="analysis-box">
    <div class="analysis-box-content flex-box">
      <el-table
        :data="data"
        style="width: 100%"
        height="310"
        :row-class-name="tableRowClassName"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column prop="recordNo" label="培训编号"> </el-table-column>
        <el-table-column prop="customerName" label="客户名称"> </el-table-column>
        <el-table-column prop="trainingType" label="培训类型"> </el-table-column>
        <el-table-column prop="trainingDate" label="培训日期"> </el-table-column>
        <el-table-column prop="fullAddress" label="培训地点"> </el-table-column>
        <el-table-column prop="participationNumber" label="参与人数"> </el-table-column>
        <el-table-column prop="trainingSatisfaction" label="客户满意度">
          <template slot-scope="{ row, column, $index }">
            <el-rate disabled v-model="row.trainingSatisfaction"></el-rate>
          </template>
        </el-table-column>
        <el-table-column prop="expenditure" label="费用花销(元)"> </el-table-column>
        <!-- <el-table-column prop="remark" label="备注"> </el-table-column> -->
        <el-table-column prop="teacherName" label="培训人员"> </el-table-column>
        <!-- <el-table-column prop="riskType" label="培训类型"> </el-table-column>
        <el-table-column prop="participation" label="客户名称">
        </el-table-column>
        <el-table-column prop="localDate" label="培训日期"> </el-table-column>
        <el-table-column prop="trainingSite" label="培训地点">
        </el-table-column>
        <el-table-column prop="participationNumber" label="参与人数">
        </el-table-column>
        <el-table-column prop="trainingSatisfaction" label="客户满意度">
          <template slot-scope="{ row, column, $index }">
            <el-rate disabled v-model="row.trainingSatisfaction"></el-rate>
          </template>
        </el-table-column> -->
        <el-table-column prop="connectDevices" label="培训回顾">
          <template slot-scope="{ row, column, $index }">
            <el-button @click="handleReview(row)" type="text"
              >培训回顾</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <TrainingReviewDialog
      :data="reviewData.trainingReview || []"
      :show.sync="dialogVisible"
    ></TrainingReviewDialog>
  </div>
</template>
<script>
import TrainingReviewDialog from "./TrainingReviewDialog.vue";
import { getRiskTrainReview } from "@/api/riskData.js";
import { trainingRecordDetail } from "@/api/basicData";
export default {
  name: "RiskControlTrain",
  components: { TrainingReviewDialog },
  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      reviewData: [],
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "zdy-row";
      }
    },
    async handleReview(item) {
      try {
        let {code, data} = await trainingRecordDetail(item.id);
        if (code == 200) {
          this.reviewData = data;
          this.dialogVisible = true;
        }
      } catch (error) {
        
      }
      // getRiskTrainReview({ riskId: item.id })
      //   .then((res) => {
      //     this.reviewData = res.data;
      //     this.dialogVisible = true;
      //   })
      //   .catch(() => {
      //     this.reviewData = [];
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 6px 24px 0 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    overflow: auto;
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
</style>
