import { render, staticRenderFns } from "./CaseAnalysis.vue?vue&type=template&id=95a11f0e&scoped=true"
import script from "./CaseAnalysis.vue?vue&type=script&lang=js"
export * from "./CaseAnalysis.vue?vue&type=script&lang=js"
import style0 from "./CaseAnalysis.vue?vue&type=style&index=0&id=95a11f0e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95a11f0e",
  null
  
)

export default component.exports