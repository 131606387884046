<template>
  <el-drawer :visible.sync="show">
    <!-- <div class="main-container"> -->
    <div class="item first-container">
      <div
        class="item first-item"
        v-for="(item, index) in arrySettingList"
        :key="index"
      >
        <div class="switch-item">
          <div>{{ item.title }}</div>
          <el-switch
            @change="itemChange($event, item.setkey)"
            v-model="item.show"
          >
          </el-switch>
        </div>
        <!-- 二级数据项 -->
        <div
          class="item"
          v-if="item.itemDrawShow && item.items && item.items.length"
        >
          <draggable v-model="item.items">
            <transition-group>
              <div
                class="switch-item second-item"
                v-for="(sitem, sindex) in (item.items || []).filter(vv=> !vv.isNone)"
                :key="sindex + 'se' + index"
                v-show="!sitem.block"
              >
                <div>
                  <i class="el-icon-sort" style="cursor: pointer"></i
                  >{{ sitem.title }}
                </div>
                <el-switch v-model="sitem.show"> </el-switch>
              </div>
            </transition-group>
          </draggable>
        </div>
        <!-- 二级数据项(对象类型) objItems -->
        <div
          class="item"
          v-if="
            item.itemDrawShow &&
            item.objItems &&
            Object.keys(item.objItems).length
          "
        >
          <draggable>
            <transition-group>
              <div
                class="switch-item second-item"
                v-for="(ositem, osindex) in Object.keys(item.objItems)"
                :key="osindex + 'ose' + index"
              >
                <div>
                  <i class="el-icon-sort"></i>{{ item.objItems[ositem].title }}
                </div>
                <el-switch v-model="item.objItems[ositem].show"> </el-switch>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button type="info" @click="reset">恢复默认</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
      <el-button @click="$emit('change', false)">取消</el-button>
    </div>
    <!-- </div> -->
  </el-drawer>
</template>

<script>
import draggable from "vuedraggable";
import { saveDataConfig } from "@/api/SystemData";
import _ from "lodash";
export default {
  name: "SetDataDrawer",
  model: {
    prop: "drawer",
    event: "change",
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    settingList: {
      type: Object,
      default: () => {},
    },
  },
  components: { draggable },
  data() {
    return {
      show: false,
      arrySettingList: [],
      defaultSetting: {},
    };
  },
  watch: {
    drawer(newValue, oldValue) {
      this.show = newValue;
    },
    show(newValue, oldValue) {
      this.$emit("change", newValue);
    },
    settingList: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        this.arrySettingList = [];
        this.defaultSetting = _.cloneDeep(newValue);
        Object.keys(this.settingList).forEach((key) => {
          this.settingList[key]["setkey"] = key;
          this.arrySettingList.push(this.settingList[key]);
        });
      },
    },
  },
  methods: {
    confirm() {
      let simplifySet = {};
      Object.keys(this.settingList).forEach((key) => {
        let objItems = {};
        if (Object.keys(this.settingList[key]?.objItems || {}).length) {
          Object.keys(this.settingList[key].objItems).forEach((oskey) => {
            objItems[oskey] = {
              show: this.settingList[key].objItems[oskey].show,
            };
          });
        }
        simplifySet[key] = {
          show: this.settingList[key].show,
          itemDrawShow: this.settingList[key].itemDrawShow,
          items:
            this.settingList[key].itemDrawShow &&
            this.settingList[key].items.map((it) => {
              return {
                show: it.show,
                key: it.key,
              };
            }),
          objItems: objItems,
        };
      });
      saveDataConfig({
        id: this.$store.state.userInfo.MJUserId,
        contentJson: JSON.stringify(simplifySet),
      }).then((res) => {
        // if (res.code === 200) return this.init();
        this.$message({
          message: "数据设置保存成功！",
          type: "success",
        });
        this.show = false;
      });
    },
    reset() {
      Object.keys(this.settingList).forEach((key) => {
        this.settingList[key].show = true;
        if (this.settingList[key].items) {
          let items = this.settingList[key].items;
          items.forEach((item) => {
            if (!item.block) {
              item.show = true;
            }
          });
        }
        if (this.settingList[key].objItems) {
          let objItems = this.settingList[key].objItems;
          Object.keys(objItems).forEach((oskey) => {
            if (!objItems[oskey].block) {
              objItems[oskey].show = true;
            }
          });
        }
      });
    },
    itemChange(e, setkey) {
      if (e) {
        this.$emit("itemChange", setkey);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.first-container {
  max-height: 860px;
  overflow: auto;
}
.switch-item {
  display: flex;
  justify-content: space-between;
}
.item {
  display: flex;
  flex-direction: column;
}
.second-item {
  padding: 10px 48px;
}
.first-item {
  padding: 0 24px;
  margin-bottom: 10px;
}
.bottom-btn {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  align-self: flex-end;
}
</style>
