<template>
  <div class="main-container">
    <div
      class="item"
      v-for="(item, index) in setting"
      :key="index"
      :style="{ backgroundColor: item.bgc }"
      v-show="item.show"
    >
      <!-- icon -->
      <div class="icon">
        <img style="width: 37px; height: 37px" :src="item.icon" />
      </div>
      <!-- 数据，title -->
      <div style="margin-left: 10px">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="data">
          {{ data[item.key] || 0 }}
        </div>
      </div>
      <!-- 右下角图片 -->
      <div class="img">
        <img style="width: 100px; height: 100px" :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DriverOnline",
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    setting: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      itemList: [
        {
          title: "机手总数(人)",
          data: 0,
          icon: require("@/assets/images/RiskControl/ijszs.png"),
          img: require("@/assets/images/RiskControl/jszs.png"),
          bgc: "rgba(32, 203, 187, 0.1)",
          key: "jsCount",
        },
        {
          title: "持证机手(人)",
          data: 0,
          icon: require("@/assets/images/RiskControl/iczjs.png"),
          img: require("@/assets/images/RiskControl/czjs.png"),
          bgc: "rgba(240, 119, 53, 0.1)",
          key: "jsCZCount",
        },
        {
          title: "出险机手(人)",
          data: 0,
          icon: require("@/assets/images/RiskControl/icsjs.png"),
          img: require("@/assets/images/RiskControl/csjs.png"),
          bgc: "rgba(0, 175, 255, 0.1)",
          key: "jsOutCount",
        },
        {
          title: "参训机手(人)",
          data: 0,
          icon: require("@/assets/images/RiskControl/icxjs.png"),
          img: require("@/assets/images/RiskControl/cxjs.png"),
          bgc: "rgba(62, 81, 255, 0.1)",
          key: "jsCXCount",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .item {
    margin-left: 16px;
    margin-top: 16px;
    padding: 20px 16px;
    width: 286px;
    height: 134px;
    border-radius: 10px 10px 10px 10px;
    position: relative;
    display: flex;
    .icon {
      width: 37px;
      height: 37px;
    }
    .title {
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      margin-top: 12px;
    }
    .data {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
      margin-top: 20px;
    }
    .img {
      width: 100px;
      height: 100px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
