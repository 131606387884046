<template>
  <div class="mian-container">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 19px;
      "
    >
      <div style="display: flex">
        <div style="display: flex; color: #333333; font-size: 14px">
          <div
            style="
              width: 16px;
              height: 16px;
              background-color: #ff7474;
              margin-right: 2px;
            "
          ></div>
          夜间作业
        </div>
        <div
          style="
            display: flex;
            color: #333333;
            font-size: 14px;
            margin-left: 24px;
          "
        >
          <div
            style="
              width: 16px;
              height: 16px;
              background-color: #4a8afd;
              margin-right: 2px;
            "
          ></div>
          白天作业
        </div>
      </div>
      <div class="left-text">日平均工时：{{ data.avgTime || 0 }}小时</div>
    </div>

    <div class="chartBox">
      <Chart
        style="height: 100%; width: 100%"
        ref="chartBox"
        :option="gaugeOptions"
      ></Chart>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      gaugeOptions: {},
    };
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        this.setChartOption();
      },
    },
  },
  mounted() {
    this.setChartOption();
  },
  methods: {
    setChartOption() {
      let barData = [];
      let timeData = [];
      let scatterList = this.data?.scatter || [];
      if (scatterList.length) {
        for (let i = 0; i < scatterList.length; i++) {
          timeData.push((i + '-' + (i + 1) +  '工时'));
          if (i > 6 && i < 18) {
            barData.push({
              value: scatterList[i] || 0,
              itemStyle: {
                color: "#4A8AFD",
              },
            });
          } else {
            barData.push({
              value: scatterList[i] || 0,
              itemStyle: {
                color: "#FF7474",
              },
            });
          }
        }
      } else {
        for (let i = 0; i < 24; i++) {
          timeData.push(i);
          barData.push({
            value: 0,
            itemStyle: {
              color: "#FF7474",
            },
          });
        }
      }
      this.gaugeOptions = {
        tooltip: {
          //   trigger: "item", //默认效果
          //柱状图加阴影
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },
        grid: {
          top: "10%",
          left: "2%",
          right: "6%",
          bottom: "6%",
          containLabel: true,
        },
        color: ["#FF7474"],
        xAxis: {
          name: "时间",
          type: "category",
          data: timeData,
          axisLabel: {
            textStyle: {
              color: "rgba(0, 0, 0, 1)",
            },
            rotate: 90,
          },
          axisLine: {
            lineStyle: {
              color: "#cccccc",
            },
          },
        },
        yAxis: {
          name: "工作小时数",
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f5f5f5",
            },
          },
        },
        series: [
          {
            name: "时间",
            data: barData,
            barWidth: 15,
            type: "bar",
          },
        ],
      };
    },
    handleChartClick(e) {
      console.log(e);
    },
    handleSelect() {
      this.$emit("changeUnit", this.timeType, "workUnit");
    },
  },
};
</script>

<style lang="scss" scoped>
.mian-container {
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .left-text {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    text-align: right;
  }
  .chartBox {
    height: 340px;
  }
}
// .analysis-box {
//   //padding: 16px 24px;
//   margin-bottom: 10px;
//   background: #ffffff;
//   border-radius: 4px;
//   &-title {
//     position: relative;
//     font-size: 18px;
//     font-weight: bold;
//     color: #333333;
//     margin-bottom: 24px;
//     &-right {
//       font-size: 14px;
//       font-weight: 400;
//       color: #333333;
//       &-x {
//         display: flex;
//         justify-content: flex-end;
//         font-size: 12px;
//         color: #9c9c9c;
//         margin-bottom: 8px;
//       }
//     }
//   }
//   &-title::before {
//     content: "";
//     position: absolute;
//     left: -24px;
//     top: 0;
//     bottom: 0;
//     width: 6px;
//     background: #4278c9;
//   }
//   &-content {
//     overflow: auto;
//   }
// }
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
