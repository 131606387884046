<template>
  <div class="mian-container">
    <!-- 上方统计卡片 -->
    <div class="total">
      <div
        class="total-item"
        :style="{ background: item.bgc, color: item.textc }"
        v-for="(item, index) in itemList"
        :key="index"
      >
        <div class="title">
          {{ item.title }}
        </div>
        <div class="data">
          {{ addThousandSeparator(data[item.key] || 0) }}{{ item.unit }}
        </div>
        <div class="img">
          <img style="width: 70px; height: 70px" :src="item.icon" />
        </div>
      </div>
    </div>
    <!-- 饼图 -->
    <div class="chart">
      <Chart class="chart" :option="chartOption"></Chart>
    </div>
  </div>
</template>

<script>
import { addThousandSeparator } from "@/utils/dealNumber.js";
import Chart from "@/components/Chart/Charts";
export default {
  name: "EquipmentOnline",
  components: { Chart },
  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      addThousandSeparator: addThousandSeparator,
      itemList: [
        {
          title: "设备总数(台)",
          data: 0,
          textc: "#333333",
          bgc: "linear-gradient( 163deg, #EAEDF2 0%, #FAFBFC 100%)",
          icon: require("@/assets/images/RiskControl/ep-total.png"),
          key: "devicesTotal",
          unit: "",
        },
        {
          title: "在线设备(台)",
          data: 0,
          textc: "#0D2A6E",
          bgc: "linear-gradient( 163deg, #E4ECFE 0%, #F8FBFE 100%)",
          icon: require("@/assets/images/RiskControl/ep-zxsb.png"),
          key: "onlineDevices",
          unit: "",
        },
        {
          title: "昨天在线设备(台)",
          data: 0,
          textc: "#538697",
          bgc: "linear-gradient( 163deg, #FEF4E5 0%, #FFFDF8 100%)",
          icon: require("@/assets/images/RiskControl/zrzxs.png"),
          key: "yesterdayOnlineDevices",
          unit: "",
        },
        {
          title: "在线率",
          data: 0,
          textc: "#538697",
          bgc: "linear-gradient( 160deg, #E1F3FF 0%, #F8FDFF 100%)",
          icon: require("@/assets/images/RiskControl/ep-zxl.png"),
          key: "onlineRate",
          unit: "%",
        },
      ],
      chartOption: {},
      dataList: [],
    };
  },
  mounted() {
    this.setChartOption();
  },
  watch: {
    data: {
      handler() {
        this.setChartOption();
      },
    },
  },
  methods: {
    setChartOption() {
      let that = this;
      let colorList = [
        "#DDE0E2",
        "#B6CAF7",
        "#1159FF",
        "#64B2FF",
        "#66D3FB",
        "#B3E8FB",
        "#DDE0E2",
      ];
      if (!this.data?.alarmProportionList?.length) {
        this.dataList = [
          {
            typeName: "",
            name: "",
            value: 0,
            rate: "",
          },
        ];
      } else {
        this.dataList = this.data.alarmProportionList?.map((item, index) => {
          return {
            typeName: item.alarmName,
            name: item.alarmType,
            value: item.alarmTimes || 0,
            rate: item.alarmRate || 0,
          };
        });
      }

      let center = ["25%", "50%"];

      this.chartOption = {
        color: colorList,
        legend: {
          type: "scroll",
          y: "center",
          left: "50%",
          orient: "vertical", //改变排列方式
          icon: "circle", //改变legend小图标形状
          itemGap: 16, // 设置legend的间距
          itemWidth: 16, // 设置宽度
          itemHeight: 16, // 设置高度
          formatter: function (name) {
            for (let i = 0; i < that.dataList.length; i++) {
              if (that.dataList[i].name == name) {
                return `{a|${that.dataList[i].typeName}}|{b|${that.dataList[i].rate}%}{c|${that.dataList[i].value}次}`;
              }
            }
          },
          textStyle: {
            rich: (function () {
              return {
                a: {
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#333333",
                  padding: [0, 8, 0, 8],
                },
                c: {
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#333333",
                  padding: [0, 0, 0, 10],
                },
                b: {
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#999999",
                  padding: [0, 0, 0, 8],
                },
              };
            })(),
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function (params, ticket, callback) {
            return `<div style="font-size: 12px;font-weight: 400">
                      <div style="display:flex;border-bottom: 1px solid rgba(255,255,255,0.1);">
                        <div>${params.data.rate || 0}%</div>
                        <div style='margin:0 0 0 42px'>${
                          params.data.value || 0
                        }次</div>
                      </div>
                      <div style="display:flex;align-items: center;">
                        <div style="height: 14px;width: 14px;border-radius: 50%;background-color:${
                          params.color
                        }"></div>
                        <div style='margin:0 0 0 2px'>${
                          params.data.typeName
                        }</div>
                      </div>
                    </div>`;
          },
          backgroundColor: "#292C35",
          textStyle: {
            color: "#fff",
          },
          padding: [10, 8],
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: "告警总数",
            type: "pie",
            center: center,
            radius: ["90%", "65%"],
            itemStyle: {
              borderWidth: 5, //描边线宽
              borderColor: "#fff",
              borderRadius: 8,
            },
            label: {
              show: true,
              position: "center",
              formatter: [
                "{a|告警总数（次）}",
                `{b|${that.data.alarmTotalTimes || 0}}`,
              ].join("\n"),
              shadowColor: "#e3e3e3",
              shadowBlur: 10,
              borderRadius: 200,
              rich: {
                a: {
                  fontWeight: "400",
                  padding: [0, 0, 0, 0],
                  color: "#333333",
                  fontSize: 14,
                },
                b: {
                  fontWeight: "bold",
                  padding: [5, 0, 0, 0],
                  color: "#333333",
                  fontSize: 24,
                },
              },
            },
            emphasis: {
              scale: 1.1,
            },
            selectedMode: "series",
            selectedOffset: 100,
            select: {},
            data: this.dataList,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mian-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  //justify-content: space-between;
  .total {
    display: flex;
    flex-wrap: wrap;
    //flex: 1;
    justify-content: space-around;
    margin-bottom: 32px;
    &-item {
      // width: 190px;
      width: 48%;
      height: 90px;
      border-radius: 10px 10px 10px 10px;
      padding: 16px;
      // margin-left: 15px;
      margin-top: 16px;
      position: relative;
      box-sizing: border-box;
      .title {
        font-weight: 400;
        font-size: 14px;
      }
      .data {
        font-weight: bold;
        font-size: 24px;
        margin-top: 8px;
      }
      .img {
        width: 70px;
        height: 70px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .chart {
    width: 100%;
    height: 300px;
  }
}
</style>
