<template>
  <div class="main-card">
    <!-- 标题和右边操作按钮 -->
    <div
      class="title-action"
      :style="{ borderBottom: border ? '1px solid #f2f7fd' : '' }"
    >
      <div class="title">
        <div>{{ title }}</div>
      </div>
      <slot name="action"></slot>
    </div>
    <!-- <div style="display: flex; flex: 1">
      <slot name="main"></slot>
    </div> -->
    <slot name="main"></slot>
  </div>
</template>

<script>
export default {
  name: "CardBox",
  props: {
    title: {
      type: String,
      default: "",
    },
    border: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: String,
      default: "16px",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  .title-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 14px 0;
    min-height: 60px;
    .title {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }
    .title::before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      width: 4px;
      height: 18px;
      background: #4278c9;
    }
  }
}
</style>
