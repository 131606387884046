<template>
  <div class="mian-container">
    <div
      class="item"
      v-for="(item, index) in setting"
      v-show="item.show"
      :key="index"
    >
      <div class="img">
        <img :src="item.icon" style="width: 35px; height: 35px" />
      </div>
      <div class="right">
        <div class="title">{{ item.title }}</div>
        <div class="data">{{ data[item.key] || 0 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceOnline",
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    setting: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      itemList: [
        {
          title: "案件总数(个)",
          data: 0,
          icon: require("@/assets/images/RiskControl/ajzs.png"),
          key: "caseCount",
        },
        {
          title: "出险总金额(元)",
          data: 0,
          icon: require("@/assets/images/RiskControl/cxzje.png"),
          key: "riskTotalAmount",
        },
        {
          title: "已结案件赔付金额(元)",
          data: 0,
          icon: require("@/assets/images/RiskControl/ypfje.png"),
          key: "compensateAmount",
        },
        {
          title: "未结案件预估金额(元)",
          data: 0,
          icon: require("@/assets/images/RiskControl/wpfje.png"),
          key: "notRiskAmount",
        },
        {
          title: "0赔付案件(个)",
          data: 0,
          icon: require("@/assets/images/RiskControl/0pfaj.png"),
          key: "zeroCompensateCount",
        },
        {
          title: "0赔付案件预估金额(元)",
          data: 0,
          icon: require("@/assets/images/RiskControl/0pfje.png"),
          key: "zeroRiskAmount",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.mian-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
  .item {
    display: flex;
    align-items: center;
    height: 55px;
    width: 260px;
    margin-top: 40px;
    margin-left: 24px;
    .img {
      width: 55px;
      height: 55px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 1px rgba(28, 43, 187, 0.08);
      border-radius: 4px 4px 4px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right {
      margin-left: 16px;
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
      .data {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
        margin-top: 5px;
      }
    }
  }
}
</style>
