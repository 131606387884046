var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.type === 'type'),expression:"data.type === 'type'"}],staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"data":_vm.tableData,"span-method":_vm.objectSpanMethod,"cell-class-name":_vm.tableRowClassName,"height":"300"}},[_c('template',{slot:"empty"},[_c('el-empty',{attrs:{"description":"列表暂无数据","image":require('@/assets/images/RiskControl/risk-empty.png')}})],1),_c('el-table-column',{attrs:{"width":"50","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row, column, $index }){return _c('div',{staticClass:"tag",style:({
          color: _vm.colours[($index / 2) % 5].tc,
          backgroundColor: _vm.colours[($index / 2) % 5].bc,
        })},[_vm._v(" "+_vm._s($index / 2 + 1)+" ")])}}])}),_c('el-table-column',{attrs:{"prop":"dangerType","label":"案件类型","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function({ row, column, $index }){return ($index % 2 === 0)?_c('div',{},[_vm._v(" "+_vm._s(row.dangerType || "---")+" ")]):_c('div',[_c('el-progress',{attrs:{"show-text":false,"stroke-width":14,"percentage":row.dangerType}})],1)}}],null,true)}),_c('el-table-column',{attrs:{"prop":"amountPayable","label":"赔付金额(元)","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row, column, $index }){return ($index % 2 === 0)?_c('span',{},[_vm._v(" "+_vm._s(_vm.addThousandSeparator(row.amountPayable) || 0)+" ")]):_c('span',{staticClass:"rate"},[_vm._v(_vm._s(row.dangerType || "---")+"%")])}}],null,true)}),_c('el-table-column',{attrs:{"prop":"dangerCount","label":"出险次数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"dangerCountPercent","label":"次数占比","align":"center"}})],2),_c('el-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.type === 'address'),expression:"data.type === 'address'"}],staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"data":_vm.tableData,"span-method":_vm.objectSpanMethod2,"cell-class-name":_vm.tableRowClassName,"height":"300"}},[_c('template',{slot:"empty"},[_c('el-empty',{attrs:{"description":"列表暂无数据","image":require('@/assets/images/RiskControl/risk-empty.png')}})],1),_c('el-table-column',{attrs:{"width":"50","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row, column, $index }){return _c('div',{staticClass:"tag",style:({
          color: _vm.colours[($index / 2) % 5].tc,
          backgroundColor: _vm.colours[($index / 2) % 5].bc,
        })},[_vm._v(" "+_vm._s($index / 2 + 1)+" ")])}}])}),_c('el-table-column',{attrs:{"prop":"dangerProvince","label":"案件地区","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function({ row, column, $index }){return ($index % 2 === 0)?_c('div',{},[_vm._v(" "+_vm._s(row.dangerProvince || row.dangerAddr || "---")+" ")]):_c('div',[_c('el-progress',{attrs:{"show-text":false,"stroke-width":14,"percentage":row.dangerProvince || row.dangerAddr}})],1)}}],null,true)}),_c('el-table-column',{attrs:{"prop":"deviceCount","label":"设备总数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row, column, $index }){return ($index % 2 === 0)?_c('span',{},[_vm._v(" "+_vm._s(row.deviceCount)+" ")]):_c('span',{staticClass:"rate"},[_vm._v(_vm._s(row.deviceCount || "---")+"%")])}}],null,true)}),_c('el-table-column',{attrs:{"prop":"amountPayable","label":"赔付金额","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row, column, $index }){return _c('span',{},[_vm._v(" "+_vm._s(_vm.addThousandSeparator(row.amountPayable) || 0)+" ")])}}])}),_c('el-table-column',{attrs:{"prop":"dangerCount","label":"出险次数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"dangerCountPercent","label":"次数占比","align":"center"}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }