<template>
  <div class="analysis-box">
    <div class="analysis-box-content flex-box">
      <el-table
        :data="data.jsInfoList || []"
        style="width: 100%"
        height="310"
        :row-class-name="tableRowClassName"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column prop="date" label="头像">
          <template slot-scope="scope">
            <img
              :src="
                scope.row.avatar ||
                require('@/assets/images/RiskControl/toux.png')
              "
              style="width: 48px; height: 48px; border-radius: 50%"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="age" label="年龄"> </el-table-column>
        <el-table-column prop="workAge" label="工龄"> </el-table-column>
        <el-table-column prop="isControls" label="是否有操作证">
          <template slot-scope="{ row, column, $index }">
            <span>{{ row.isControls ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outRiskNumber" label="出险次数">
        </el-table-column>
        <el-table-column prop="zeroAccidentDay" label="连续无事故天数">
        </el-table-column>
        <!-- <el-table-column prop="trainNumber" label="培训次数"> </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "zdy-row";
      }
    },
    handleImport() {
      this.$emit("handleImport", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 6px 24px 0 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    overflow: auto;
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
</style>
