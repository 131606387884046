<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>数据分析</span>
      <!-- <el-select v-model="timeType" placeholder="请选择">
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
    </div>
    <div
      class="analysis-box-content flex-box"
      v-if="Object.keys(details).length !== 0"
    >
      <div
        class="analysis-box-content-analysis flex-1"
        :style="{
          background: item.bgColor,
          marginRight: index < analysisShowList.length - 1 ? '20px' : '0',
        }"
        v-for="(item, index) in analysisShowList"
        :key="index"
      >
        <div class="analysis-box-content-analysis-title flex-box flex-items">
          <img
            :src="item.icon"
            class="analysis-box-content-analysis-title-icon margin-r"
            alt=""
            srcset=""
          />
          <div class="analysis-box-content-analysis-title-name">
            {{ item.title }}
          </div>
        </div>
        <div
          v-if="item.type == '1'"
          class="analysis-box-content-analysis-item flex-box flex-between"
        >
          <div class="flex-1 flex-box justify-center">
            <Chart
              style="height: 140px; width: 140px"
              ref="chartBox"
              :option="gaugeOptions"
            ></Chart>
          </div>
          <div class="flex-1">
            <div class="analysis-box-content-analysis-item-s">
              <div class="analysis-box-content-analysis-item-s-title">
                赔付金额
              </div>
              <div
                class="analysis-box-content-analysis-item-s-data flex-box items-end"
              >
                <div
                  class="analysis-box-content-analysis-item-s-data-num margin-r"
                >
                  {{ details.claimInfo.pfAmount || 0 }}
                </div>
                <div class="analysis-box-content-analysis-item-s-data-unit">
                  万元
                </div>
              </div>
            </div>
            <div class="analysis-box-content-analysis-item-s">
              <div class="analysis-box-content-analysis-item-s-title">
                案件数
              </div>
              <div
                class="analysis-box-content-analysis-item-s-data flex-box items-end"
              >
                <div
                  class="analysis-box-content-analysis-item-s-data-num margin-r"
                >
                  {{ details.claimInfo.claimCount || 0 }}
                </div>
                <div class="analysis-box-content-analysis-item-s-data-unit">
                  件
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="analysis-box-content-analysis-item">
          <div class="analysis-box-content-analysis-item-type">
            <div
              class="analysis-box-content-analysis-item-type-item"
              :style="{ background: typeItem.bgColor }"
              v-for="(typeItem, typeIndex) in item.itemList"
              :key="typeIndex"
            >
              <div
                class="analysis-box-content-analysis-item-type-item-num"
                :style="{ color: typeItem.textColor }"
              >
                {{ getNestedValue(details, typeItem.key) }}
              </div>
              <div class="analysis-box-content-analysis-item-type-item-unit">
                {{ typeItem.text }}
                <span v-if="typeItem.unit">（{{ typeItem.unit }}）</span>
              </div>
            </div>
          </div>
          <div class="analysis-box-content-analysis-item-particular">
            <div
              class="analysis-box-content-analysis-item-particular-item"
              v-for="(typeItem, typeIndex) in item.particularList"
              :key="typeIndex"
            >
              {{ typeItem.text }}
              <span
                class="analysis-box-content-analysis-item-particular-item-num"
                >{{ getNestedValue(details, typeItem.key) }}</span
              >
              {{ typeItem.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    analysisList: {
      type: Array,
      default: () => [],
    },
    details: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    analysisShowList() {
      return this.analysisList.filter((val) => val.showBox);
    },
  },
  watch: {
    analysisList: {
      handler(v) {
        this.$nextTick(() => {
          this.gaugeOptions = {
            grid: {
              left: 6,
              right: 6,
            },
            series: [
              {
                type: "gauge",
                radius: "98%",
                progress: {
                  show: true,
                  itemStyle: {
                    color: "#2890EB",
                  },
                  width: 8,
                  roundCap: true,
                },
                axisLine: {
                  roundCap: true,
                  lineStyle: {
                    width: 8,
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: false,
                  length: 15,
                  lineStyle: {
                    width: 2,
                    color: "#999",
                  },
                },
                axisLabel: {
                  show: false,
                  distance: 25,
                  color: "#999",
                  fontSize: 20,
                },
                anchor: {
                  show: false,
                  showAbove: true,
                  size: 12,
                  itemStyle: {
                    borderWidth: 10,
                  },
                },
                title: {
                  show: true,
                  fontSize: 14,
                  offsetCenter: [0, "25%"],
                },
                detail: {
                  valueAnimation: true,
                  formatter: "{value}%",
                  fontSize: 24,
                  fontWeight: "bold",
                  offsetCenter: [0, "-25%"],
                },
                pointer: {
                  show: false,
                },
                data: [
                  {
                    value: this.details.claimInfo && this.details.claimInfo.pfRation ? this.details.claimInfo.pfRation : 0,
                    name: "赔付率",
                  },
                ],
              },
            ],
          };
        });
      },
    },
    details: {
      handler(v, oldVal) {
        this.$nextTick(() => {
          this.gaugeOptions = {
            grid: {
              left: 6,
              right: 6,
            },
            series: [
              {
                type: "gauge",
                radius: "98%",
                progress: {
                  show: true,
                  itemStyle: {
                    color: "#2890EB",
                  },
                  width: 8,
                  roundCap: true,
                },
                axisLine: {
                  roundCap: true,
                  lineStyle: {
                    width: 8,
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: false,
                  length: 15,
                  lineStyle: {
                    width: 2,
                    color: "#999",
                  },
                },
                axisLabel: {
                  show: false,
                  distance: 25,
                  color: "#999",
                  fontSize: 20,
                },
                anchor: {
                  show: false,
                  showAbove: true,
                  size: 12,
                  itemStyle: {
                    borderWidth: 10,
                  },
                },
                title: {
                  show: true,
                  fontSize: 14,
                  offsetCenter: [0, "25%"],
                },
                detail: {
                  valueAnimation: true,
                  formatter: "{value}%",
                  fontSize: 24,
                  fontWeight: "bold",
                  offsetCenter: [0, "-25%"],
                },
                pointer: {
                  show: false,
                },
                data: [
                  {
                    value: this.details.claimInfo.pfRation || 0,
                    name: "赔付率",
                  },
                ],
              },
            ],
          };
        });
      },
    },
  },
  data() {
    return {
      timeType: "",
      timeOptions: [
        {
          label: "本周",
          value: 1,
        },
      ],
      gaugeOptions: {},
    };
  },
  mounted() {},
  methods: {
    getNestedValue(obj, keys) {
      if (keys.length === 1) {
        return obj[keys[0]];
      }
      return this.getNestedValue(obj[keys[0]], keys.slice(1));
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    padding: 0 16px;
    overflow: auto;
    &-analysis {
      min-width: 380px;
      height: 213px;
      border-radius: 8px;
      padding: 16px;
      &-title {
        margin-bottom: 12px;
        &-icon {
          width: 32px;
          height: 32px;
        }
        &-name {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
      &-item {
        &-s {
          height: 50%;
          // display: flex;
          // flex-direction: column;
          // justify-content: flex-end;
          &-title {
            position: relative;
            padding-left: 14px;
            font-size: 14px;
            color: #666666;
          }
          &-title::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background-color: rgba(40, 144, 235, 1);
            border-radius: 50%;
          }
          &-title::after {
            content: "";
            position: absolute;
            left: 2px;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background-color: #fff;
            border-radius: 50%;
          }
          &-data {
            margin-top: 8px;
            &-num {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
            }
            &-unit {
              font-size: 12px;
              color: #bbbbbb;
            }
          }
        }
        &-type {
          display: flex;
          justify-content: space-around;
          &-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 168px;
            height: 98px;
            border-radius: 8px;
            margin-bottom: 12px;
            padding: 20px 0;
            text-align: center;
            &-num {
              font-size: 24px;
              font-weight: bold;
            }
            &-unit {
              font-size: 14px;
              color: #666;
            }
          }
        }
        &-particular {
          display: flex;
          justify-content: space-around;
          &-item {
            font-size: 14px;
            color: #666666;
            &-num {
              font-weight: bold;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
