<template>
  <div class="mian-container">
    <div class="safe-Data">
      <!-- 饼 -->
      <div class="chart">
        <Chart :option="chartOption"></Chart>
        <div class="update">
          更新于{{ data.faceData["yesterdayScoreDateTime"] || "--- -- --" }}
        </div>
        <div
          class="risk-level"
          :style="{
            backgroundColor:
              getRiskLevel(data.faceData.yesterdayScore).color || '',
            boxShadow: getRiskLevel(data.faceData.yesterdayScore).shadow || '',
          }"
        >
          {{ getRiskLevel(data.faceData["yesterdayScore"] || "").text }}
        </div>
      </div>
      <!-- 文字新闻 -->
      <div class="news">
        <template
          v-if="
            data.faceData.suggestionList && data.faceData.suggestionList.length
          "
        >
          <div
            class="news-item"
            v-for="(suggestion, index) in data.faceData.suggestionList"
            :key="index"
          >
            <span>{{ (index || 0) + 1 }}、</span><span>{{ suggestion }}</span>
          </div>
        </template>
        <div v-else class="news-item">
          <span
            >当前安全状况极佳，您无需担心，请继续保持当前安全防护措施和良好习惯，并保持警惕</span
          >
        </div>
      </div>
    </div>
    <!-- 底部指标 -->
    <!-- <div class="safe-bottom">
      <div class="safe-bottom-indicator">
        <div>
          上周安全指数
          <span class="safe-bottom-indicator-data">{{
            data.faceData["lastWeekScore"] || 0
          }}</span>
        </div>
        <div style="margin-left: 60px">
          前周安全指数
          <span class="safe-bottom-indicator-data">{{
            data.faceData["beforeWeekLastScore"] || 0
          }}</span>
        </div>
      </div>
      <div @click="recordShow = true" class="safe-bottom-record">
        <img
          style="width: 16px; height: 16px; margin-right: 2px"
          src="@/assets/images/RiskControl/records.png"
        />评分记录
      </div>
    </div> -->
    <el-dialog title="评分记录" :visible.sync="recordShow">
      <div v-loading="innerLoading">
        <div class="record-data">
          <!-- 数据卡片 -->
          <div class="record-data-card">
            <div
              v-for="(item, index) in recordItemSet.items"
              :key="index"
              class="record-data-card-item"
            >
              <div
                class="record-data-card-item-icon"
                :style="{ borderColor: item.color || '' }"
              ></div>
              <div class="record-data-card-item-left">
                <div class="record-data-card-item-left-title">
                  {{ item.title }}
                </div>
                <div
                  class="record-data-card-item-left-data"
                  style="margin-top: 8px"
                >
                  {{ data.innerData[item.key] || 0 }}
                </div>
              </div>
              <div class="record-data-card-item-right">
                <div
                  class="record-data-card-item-right-grade"
                  :style="{
                    backgroundColor:
                      getRiskLevel(data.innerData[item.key] || 0).color || '',
                    boxShadow:
                      getRiskLevel(data.innerData[item.key] || 0).shadow || '',
                  }"
                >
                  {{ getRiskLevel(data.innerData[item.key] || 0).text || "" }}
                </div>
              </div>
              <div
                :style="{ backgroundColor: item.color || '' }"
                class="record-data-card-item-bottom"
              ></div>
            </div>
          </div>
        </div>
        <div class="record-data-chart">
          <div class="record-data-chart-top">
            <div>项目安全等级趋势图</div>
            <div>
              <el-radio-group @input="changeRecordDateType" v-model="weekType">
                <el-radio-button
                  v-for="(item, index) in weekTypeList"
                  :key="index"
                  :label="item.key"
                  >{{ item.title }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </div>
          <div class="record-data-chart-bottom">
            <Chart
              style="height: 100%; width: 100%"
              :option="innerChartOption"
            ></Chart>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="recordShow = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import { getRiskLevel } from "../../js/index";
export default {
  name: "Safety",
  components: { Chart },
  props: {
    data: {
      type: [Array, Object],
      default: () => {
        return {
          faceData: {},
          innerData: {},
        };
      },
    },
    innerShow: {
      type: Boolean,
      default: false,
    },
    innerLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOption: {},
      innerChartOption: {},
      getRiskLevel: getRiskLevel,
      recordShow: false,
      weekType: "weekList",
      weekTypeList: [
        {
          key: "weekList",
          title: "本周",
        },
        {
          key: "lastWeekList",
          title: "上周",
        },
        {
          key: "beforeWeekLastList",
          title: "前周",
        },
      ],
      recordItemSet: {
        // B S Q
        weekType: "weekList",
        items: [
          {
            color: "#4D90FF",
            title: "本周安全等级",
            key: "weekScore",
          },
          {
            color: "#32D2DB",
            title: "上周安全等级",
            key: "lastWeekScore",
          },
          {
            color: "#FFA652",
            title: "前周安全等级",
            key: "beforeWeekLastScore",
          },
        ],
      },
    };
  },
  mounted() {
    this.setChartOption();
  },
  watch: {
    data: {
      deep: true,
      handler(newValue, oldValue) {
        this.setChartOption();
      },
    },
    recordShow(newValue, oldValue) {
      this.$emit("update:innerShow", newValue);
      if (newValue) {
        this.$nextTick(() => {
          this.setInnerChartOption();
        });
      }
    },
    innerShow(newValue, oldValue) {
      this.recordShow = newValue;
    },
  },
  methods: {
    changeRecordDateType(e) {
      this.setInnerChartOption(e);
    },
    setInnerChartOption(key = "weekList") {
      let arrData = [];
      let xData = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
      let data = [];
      if (
        key &&
        Reflect.has(this.data.innerData, key) &&
        this.data?.innerData[key]?.length
      ) {
        arrData = this.data.innerData[key];
        // this.data[key].forEach((item) => {
        //   data.push(item.dayScore || 0);
        // });
        this.data.innerData[key].map((item) => {
          data.push({
            value: item.dayScore || "",
            ...item,
          });
        });
      }
      this.innerChartOption = {
        backgroundColor: "#FFFFFF",
        grid: {
          top: "10%",
          bottom: "19%",
          left: "6%",
          right: "4%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#DEE3E6",
              type: "solid",
            },
          },
          formatter(params) {
            return `
            <div style="border-radius: 10px">
              <div style="color:#3470FF;font-size:14px;font-weight: bold;">安全指数 ${
                params[0].data.value || 0
              }</div>
              <div style="color:#333333;font-size:12px;font-weight: 400;">风险告警： ${
                params[0].data.alarmScore || 0
              }</div>
              <div style="color:#333333;font-size:12px;font-weight: 400;">出险案件： ${
                params[0].data.claimsScore || 0
              }</div>
              <div style="color:#333333;font-size:12px;font-weight: 400;">日均工时： ${
                params[0].data.workScore || 0
              }</div>
              <div style="color:#333333;font-size:12px;font-weight: 400;">设备保障： ${
                params[0].data.deviceScore || 0
              }</div>
            </div>
            `;
          },
        },
        xAxis: {
          boundaryGap: true, // 默认，坐标轴留白策略
          axisLine: {
            // 坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
              color: "#cccccc",
            },
          },
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            textStyle: {
              color: "#333333",
              margin: 15,
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
          },
          data: xData,
        },
        yAxis: {
          name: "安全指数",
          axisLine: {
            show: false,
          },
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            show: true,
            textStyle: {
              color: "#333333",
              margin: 15,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f5f5f5",
              type: [5, 10],
              dashOffset: 5,
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "line",
            symbol: "circle",
            symbolSize: 12,
            lineStyle: {
              width: 4,
              color: "#0069FF",
            },
            itemStyle: {
              color: "#0069FF",
              borderWidth: 3,
              borderColor: "#FFFFFF",
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#0069FF",
              },
            },

            data: data,
          },
        ],
      };
    },
    setChartOption() {
      let point = this.data.faceData["yesterdayScore"] || 0;
      let ccolor = getRiskLevel(point || 0).color || "";
      let ccolor2 = getRiskLevel(point || 0).color2 || "";
      this.chartOption = {
        title: [
          {
            text: point,
            textStyle: {
              color: "#333333",
              fontSize: 24,
            },
            itemGap: 20,
            x: "center",
            y: "center",
          },
        ],
        grid: [
          {
            top: "10%",
            width: "50%",
            left: "45%",
            containLabel: true,
          },
        ],
        angleAxis: {
          polarIndex: 0,
          min: 0,
          max: 100,
          show: false,
          // boundaryGap: ['40%', '40%'],
          startAngle: -160,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        polar: [
          {
            center: ["50%", "50%"], //中心点位置
            radius: ["100%", "70%"], //图形大小
          },
        ],
        series: [
          {
            type: "bar",
            z: 10,
            name: "完成度",
            data: [point],
            showBackground: false,
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 50, //大的占比环
            itemStyle: {
              normal: {
                opacity: 1,
                color: {
                  // 颜色渐变
                  colorStops: [
                    {
                      offset: 0,
                      color: ccolor, // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: ccolor2, // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: ccolor, // 100% 处的颜色
                    },
                  ],
                },
              },
            },
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["100%", "70%"],
            center: ["50%", "50%"], //中心点位置
            startAngle: 90,
            hoverAnimation: false,
            animation: false,
            clockWise: true,
            silent: true,
            itemStyle: {
              normal: {
                color: "#FFFFFF",
                shadowBlur: 0,
                shadowColor: "#66666a",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [100],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: fit-content !important;
  min-width: 868px;
}
.mian-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px 16px 16px;
  flex: 1;
  .safe-Data {
    width: 100%;
    min-height: 251px;
    background: #f2f7fd;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    .chart {
      width: 155px;
      height: 155px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .update {
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        margin-top: 8px;
      }
      .risk-level {
        width: 48px;
        height: 27px;
        background: #00c86b;
        box-shadow: 0px 0px 10px 1px rgba(48, 255, 55, 0.26);
        border-radius: 4px 4px 4px 4px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        padding: 4px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -22px;
        right: -10px;
      }
    }
    .news {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 28px;
      height: 100%;
      overflow: auto;
      &-item {
        margin-top: 14px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .safe-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    &-indicator {
      display: flex;
      &-data {
        font-size: 16px;
        color: #333333;
        margin-left: 8px;
      }
    }
    &-record {
      color: #3470ff;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
.record-data {
  &-chart {
    margin-top: 24px;
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }
    &-bottom {
      width: 100%;
      height: 300px;
      margin-top: 11px;
    }
  }
  &-card {
    display: flex;
    justify-content: space-between;
    &-item {
      width: 265px;
      height: 93px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 10px 10px;
      position: relative;
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      //align-items: center;
      &-icon {
        width: 4px;
        height: 4px;
        background: #ffffff;
        border-width: 4px;
        border-style: solid;
        border-radius: 50%;
        border-color: #3470ff;
        box-sizing: content-box;
        margin-right: 8px;
        margin-top: 2px;
      }
      &-left {
        &-title {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
        }
        &-data {
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
      }
      &-right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        &-grade {
          width: 48px;
          height: 27px;
          background: #00c86b;
          //box-shadow: 0px 0px 10px 1px rgba(255, 165, 48, 0.26);
          border-radius: 4px 4px 4px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &-bottom {
        width: 100%;
        height: 10px;
        border-radius: 0px 0px 10px 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
