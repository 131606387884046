<template>
  <div class="analysis-box">
    <div class="analysis-box-content flex-box">
      <el-table
        :data="data"
        style="width: 100%"
        height="310"
        :row-class-name="tableRowClassName"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column prop="realCaseNo" label="报案号"> </el-table-column>
        <el-table-column prop="dangerType" label="案件类型"> </el-table-column>
        <el-table-column prop="reportCaseTime" label="报案时间"> </el-table-column>
        <el-table-column prop="statusDesc" label="案件状态"> </el-table-column>
        <el-table-column prop="amountPayable" label="赔付金额(元)">
          <template slot-scope="{ row }">
            <span
              :style="{
                color: row.amountPayable > 10000 ? '#F64A2D' : '#333333',
              }"
              >{{ addThousandSeparator(row.amountPayable) || "---" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="frameNo" label="机械编号"> </el-table-column>
        <el-table-column prop="jiShouName" label="操作机手"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { addThousandSeparator } from "@/utils/dealNumber.js";
export default {
  name: "CaseList",
  components: {},
  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    },
  },
  watch: {
    data(newValue, oldValue) {
      //this.$forceUpdate();
    },
  },
  data() {
    return {
      addThousandSeparator: addThousandSeparator,
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "zdy-row";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 6px 24px 0 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    overflow: auto;
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
</style>
