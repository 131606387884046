<template>
  <div class="main-container flex-column gray-text">
    <div v-if="data.length" class="flex-column flex-1">
      <div v-for="item in data" class="flex item">
        <!-- 图片 -->
        <div class="img">
          <img
            class="img"
            :src="`https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
              carImgList.includes(item.deviceType + item.deviceTypeValue)
														? item.deviceType + item.deviceTypeValue
														: carImgList.includes(item.deviceType)
														? item.deviceType
														: 'excavating_machinery'}.png`"
          />
        </div>
        <!-- 信息 -->
        <div
          style="margin-left: 12px"
          class="flex-1 flex-column justify-between"
        >
          <div class="flex justify-between">
            <div class="flex align-center">
              <div class="font-bold black-text">
                {{ item.alarmTypeName || "---" }}
              </div>
              <div
                class="type-name"
                :style="getAlarmTypeNameStyle(item.alarmClassName)"
              >
                {{ item.alarmClassName || "---" }}
              </div>
            </div>
            <div>{{ item.beginDatetime || "---" }}</div>
          </div>
          <div class="black-text">{{ item.deviceName || "---" }}</div>
          <div>{{ item.projectName || "---" }}</div>
          <div class="flex justify-between align-center flex-1">
            <div class="flex-1 ellipsis">
              {{ item.alarmAddress || "---" }}
            </div>
            <el-button @click="handleIntervent(item)" class="blue-btn"
              >风控干预</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex-1 flex-column">
      <el-empty
        class="flex-1"
        :image="require('@/assets/images/RiskControl/warn-empty.png')"
      >
        <div
          slot="description"
          style="font-weight: 400; font-size: 15px; color: #333333"
        >
          暂无风险告警
        </div>
      </el-empty>
    </div>
    <RiskInterventionDialog
      :show.sync="interventShow"
      :data="warnDetail"
    ></RiskInterventionDialog>
  </div>
</template>

<script>
import RiskInterventionDialog from "./RiskInterventionDialog.vue";
export default {
  name: "RiskWaring",
  components: {
    RiskInterventionDialog,
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      interventShow: false,
      warnDetail: {},
      alarmTypeNameStyles: [
        {
          name: "紧急报警",
          style: {
            color: "#F64A2D",
            background: "rgba(246,74,45,0.1)",
            border: "1px solid rgba(246,74,45,0.3)",
          },
        },
        {
          name: "天气警报",
          style: {
            color: "#FDA835",
            background: "rgba(253,168,53,0.1)",
            border: "1px solid rgba(253,168,53,0.3);",
          },
        },
        {
          name: "异常工况",
          style: {
            color: "#FF924A",
            background: "rgba(255,146,74,0.1)",
            border: "1px solid rgba(255,146,74,0.3);",
          },
        },
      ],
      carImgList: [
        "aerial_work_machinery1",
        "aerial_work_machinery2",
        "compaction_machinery",
        "excavating_machinery",
        "excavating_machinery1",
        "hoisting_machinery",
        "hoisting_machinery3",
        "mining_machinery",
        "piling_machinery1",
        "shovel_transport_machinery0",
        "shovel_transport_machinery1",
        "shovel_transport_machinery3",
        "industrial_vehicles1",
					'agriculture_mechanics1',
					'concrete_machinery3',
					'shovel_transport_machinery9',
          'diao_guan_ji0',
          'yi_dong_dian_zhan0',
          'han_jie_gong_zuo_zhan0',
          'zhong_pin_jia_re_che0',
          'new_energy_vehicles0',
      ],
    };
  },
  methods: {
    getAlarmTypeNameStyle(name) {
      let osty = this.alarmTypeNameStyles.find((item) => item.name === name);
      if (osty) {
        return osty.style;
      } else {
        return this.alarmTypeNameStyles[0].style;
      }
    },
    //获取设备对应的图片
    // getCarImg(type) {},
    handleIntervent(item) {
      this.warnDetail = item;
      this.interventShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  padding: 8px 16px;
  max-height: 460px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.font-bold {
  font-weight: bold;
}

.img {
  width: 104px;
  height: 104px;
}
.blue-btn {
  background: #4278c9;
  border: 1px solid #4278c9;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
}
.gray-text {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.black-text {
  color: #333333;
}
.item {
  padding: 9px 0px;
  border-bottom: 1px solid #f2f7fd;
}
.type-name {
  padding: 2px 8px;
  margin-left: 8px;
  border-radius: 2px;
}
.ellipsis {
  //white-space: nowrap; /* 不换行 */
  //overflow: hidden; /* 超出部分隐藏 */
  //text-overflow: ellipsis; /* 显示省略号 */
  word-wrap: break-word;
  width: 0;
}
</style>
