<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>告警占比分析</span>
      <!-- <el-select v-model="timeType" placeholder="请选择">
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
    </div>
    <div class="analysis-box-content flex-box">
      <Chart
        style="height: 310px; width: 100%; flex: 1"
        ref="chartBox"
        :option="gaugeOptions"
      ></Chart>
      <div class="analysis-box-content-legend flex-1">
        <div
          class="analysis-box-content-legend-item"
          v-for="(item, index) in legendList"
          :key="index"
        >
          <div class="analysis-box-content-legend-title">
            <div
              class="analysis-box-content-legend-title-circle"
              :style="{ backgroundColor: item.bgColor }"
            ></div>
            {{ item.label }}
          </div>
          <div class="analysis-box-content-legend-num">{{ item.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    details: {
      handler(v) {
        var arr = v.alarmData.alarmProportionList.map((el) => {
          return {
            name: el.alarmName,
            value: Number(el.alarmTimes),
            bfb:
              (Number(el.alarmTimes) / Number(v.alarmData.alarmTotalTimes)) *
                100 +
              "%",
          };
        });
        this.legendList = v.alarmData.alarmProportionList.map((el, index) => {
          return {
            label: el.alarmName + " - " + el.alarmRate + "%",
            value: Number(el.alarmTimes),
            bgColor: this.colorList[index],
          };
        });
        this.$nextTick(() => {
          this.gaugeOptions = {
            animation: true,
            title: {
              text: v.alarmData.alarmTotalTimes,
              subtext: "告警总数",
              x: "center",
              y: "center",
              textStyle: {
                color: "#003b79",
                fontSize: 25,
                fontWeight: "600",
                align: "center",
                // "width": "200px"
              },
              subtextStyle: {
                color: "#003b79",
                fontSize: 12,
                fontWeight: "normal",
                align: "center",
              },
            },
            tooltip: {
              trigger: "item",
              confine: true,
              axisPointer: {
                type: "shadow",
              },
            },
            series: [
              {
                type: "pie",
                center: ["50%", "50%"],
                radius: ["70%", "88%"],
                itemStyle: {
                  normal: {
                    shadowBlur: 10,
                    shadowColor: "#aec3cb",
                  },
                },
                color: this.colorList,
                startAngle: 135,
                labelLine: {
                  normal: {
                    length: 25,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
                data: arr,
              },
            ],
          };
        });
      },
    },
  },
  data() {
    return {
      timeType: "",
      timeOptions: [
        {
          label: "本周",
          value: 1,
        },
      ],
      gaugeOptions: {},
      dataGather: {
        nonono: 2222,
      },
      legendList: [],
      colorList: [
        "#2890EB",
        "#61D8EB",
        "#A77BF3",
        "#FBC226",
        "#F8F852",
        "#54DF8B",
        "#FE2C8A",
      ],
    };
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    overflow: auto;
    &-legend {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 24px;
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #333333;
        margin-bottom: 19px;
      }
      &-title {
        position: relative;
        padding-left: 24px;
        &-circle {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }
      }
      &-num {
      }
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
