<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>报警趋势分析</span>
      <el-select v-model="timeType" placeholder="请选择" @change="handleSelect">
        <el-option
          v-for="item in timeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="analysis-box-content flex-box">
      <Chart
        style="height: 310px; width: 100%"
        ref="chartBox"
        :option="gaugeOptions"
      ></Chart>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    details: {
      handler(v, oldVal) {
        var date = [];
        var value = [];
        Object.keys(v.alarmData.alarmTrendMap).forEach((key, index) => {
          date.push(key);
          v.alarmData.alarmTrendMap[key].forEach((el, idx) => {
            if (index === 0) {
              let temp = {
                label: el.alarmName,
                value: [],
              };
              temp.value.push(el.alarmTimes);
              value.push(temp);
            } else {
              let cur = value.find((ele) => {
                return ele.label === el.alarmName;
              });
              if (cur) return cur.value.push(el.alarmTimes);
            }
          });
        });
        this.$nextTick(() => {
          this.gaugeOptions = {
            xAxis: {
              type: "category",
              name: "日期",
              data: date,
              splitLine: {
                lineStyle: {
                  color: "#DEDEDE",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#DEDEDE",
                },
              },
              axisTick: {
                inside: true,
              },
              axisLabel: {
                color: "#333",
              },
              nameTextStyle: {
                color: "#333",
              },
            },
            grid: {
              top: "11%", // 等价于 y: '16%'
              left: "2%",
              right: "17%",
              bottom: "2%",
              containLabel: true,
            },
            yAxis: {
              name: "次数",
              type: "value",
              splitNumber: 5,
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#DEDEDE",
                },
              },
              axisLabel: {
                color: "#333",
              },
              nameTextStyle: {
                color: "#333",
              },
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              show: true,
              orient: "vertical",
              icon: "circle",
              textStyle: {
                color: "#333",
              },
              right: "2",
              top: "20",
              data: value.map((el) => {
                return el.label;
              }),
            },
            series: value.map((el) => {
              return {
                name: el.label,
                data: el.value,
                type: "line",
                showSymbol: false,
              };
            }),
          };
        });
      },
    },
  },
  data() {
    return {
      timeType: "M",
      timeOptions: [
        {
          label: "本周",
          value: "W",
        },
        {
          label: "本月",
          value: "M",
        },
      ],
      gaugeOptions: {},
      dataGather: {
        nonono: 2222,
      },
    };
  },
  created() {},
  methods: {
    handleSelect() {
      this.$emit("changeUnit", this.timeType, "alarmUnit");
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    overflow: auto;
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
