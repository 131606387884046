<template>
  <div class="main-container">
    <template v-for="(item, index) in (setting || []).filter(vv=> !vv.isNone)">
      <div class="item" :style="getcompareStyle(item)" v-if="item.show">
        <div>
          <div class="item-title">{{ item.title }}</div>
          <div style="margin-top: 20px">
            <span class="item-data">{{
              addThousandSeparator(data[item.key] || 0)
            }}</span>
            <span class="item-unit">{{ item.unit }}</span>
          </div>
        </div>
        <div v-if="item.isChart" class="chart">
          <Chart :option="getChartOption(item)"></Chart>
        </div>
        <!-- 右下角图标 -->
        <div v-if="item.icon" class="item-icon">
          <img style="width: 50px; height: 50px" :src="item.icon" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import { addThousandSeparator } from "@/utils/dealNumber.js";
export default {
  name: "ProjectIndicat",
  props: {
    setting: {
      type: [Object, Array],
      default: () => [],
    },
    data: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
  },
  components: { Chart },
  data() {
    return {
      addThousandSeparator: addThousandSeparator,
      itemList: [],
    };
  },
  methods: {
    getcompareStyle(item = {}) {
      if (item.compareKeySet) {
        if (this.data[item.key] < this.data[item.compareKeySet.key]) {
          return item.compareKeySet.style || {};
        }
      }
    },
    getChartOption(item = {}) {
      let point = this.data[item.key];
      let chartOption = {
        grid: [
          {
            top: "10%",
            width: "50%",
            left: "45%",
            containLabel: true,
          },
        ],
        angleAxis: {
          polarIndex: 0,
          min: 0,
          max: 100,
          show: false,
          // boundaryGap: ['40%', '40%'],
          startAngle: 90,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        polar: [
          {
            center: ["50%", "50%"], //中心点位置
            radius: ["100%", "70%"], //图形大小
          },
        ],
        series: [
          {
            type: "bar",
            z: 10,
            name: "完成度",
            data: [point],
            showBackground: false,
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 50, //大的占比环
            itemStyle: {
              normal: {
                opacity: 1,
                color: {
                  // 颜色渐变
                  colorStops: [
                    {
                      offset: 0,
                      color: item.chartStyle.backgroundColor, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: item.chartStyle.backgroundColor, // 100% 处的颜色
                    },
                  ],
                },
              },
            },
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["100%", "70%"],
            center: ["50%", "50%"], //中心点位置
            startAngle: 90,
            hoverAnimation: false,
            animation: false,
            clockWise: true,
            silent: true,
            itemStyle: {
              normal: {
                color: item.chartStyle.backgroundColor2,
                shadowBlur: 0,
                shadowColor: "#66666a",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [100],
          },
        ],
      };
      return chartOption;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  .item {
    width: 290px;
    height: 110px;
    background: #f2f7fd;
    border-radius: 10px 10px 10px 10px;
    padding: 14px 16px;
    margin-bottom: 16px;
    margin-left: 14px;
    box-sizing: border-box;
    position: relative;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chart {
      width: 56px;
      height: 56px;
    }
    &-title {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
    &-data {
      font-weight: bold;
      font-size: 24px;
    }
    &-unit {
      font-weight: 400;
      font-size: 14px;
    }
    &-icon {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
