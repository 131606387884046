<template>
  <el-dialog
    @close="closedMainDialog"
    title="告警详情"
    :visible.sync="dialogVisible"
    width="868px"
  >
    <div style="padding: 0 16px 16px 16px">
      <CardBox :title="data.alarmTypeName || '报警'" :border="false">
        <div slot="action" style="display: flex; flex: 1; padding-left: 16px">
          <div :style="getAlarmTypeStyle(data.alarmClassName)">
            {{ data.alarmClassName || "---" }}
          </div>
        </div>
        <div slot="main">
          <!-- 设备信息-->
          <div class="info">
            <div
              v-for="(item, index) in infoItems"
              :key="index"
              class="info-item"
            >
              <div class="info-item-title">{{ item.title }}</div>
              <div class="info-item-content">{{ data[item.key] || "---" }}</div>
            </div>
          </div>
          <div style="position: relative" class="tabs-container">
            <div @click="downloadFile" class="download-file">
              <i class="el-icon-download"></i>
              下载附件
            </div>
            <el-tabs type="card" v-model="activeTabs">
              <el-tab-pane label="报警影像" name="1">
                <div
                  v-if="
                    warnDetail.alarmAttachmentVOList &&
                    warnDetail.alarmAttachmentVOList.length
                  "
                  class="flex-box"
                >
                  <div
                    v-for="(item, index) in warnDetail.alarmAttachmentVOList"
                    class="item-warn"
                  >
                    <!-- 图片/视频 -->
                    <div class="item-warn-img">
                      <video
                        class="warn-img"
                        v-if="vedioType.includes(item.fileType)"
                        controls
                        :src="item.filePath"
                      ></video>
                      <el-image
                        v-else
                        class="warn-img"
                        @click="handleImgClick(item.attUrl)"
                        :src="item.attUrl"
                        fit="contain"
                      ></el-image>
                    </div>
                    <!-- 文件名 -->
                    <div class="item-name">{{ item.attName }}</div>
                  </div>
                </div>
                <div v-else class="flex-center-column">
                  <div class="img-box">
                    <img
                      style="width: 100%; height: 100%"
                      src="@/assets/images/RiskControl/nocontent.png"
                    />
                  </div>
                  <div>暂无报警影像</div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="定位" name="2">
                <div
                  v-if="data.lon && data.lat"
                  ref="container"
                  class="map"
                ></div>
                <div v-else class="flex-center-column">
                  <div class="img-box">
                    <img
                      style="width: 100%; height: 100%"
                      src="@/assets/images/RiskControl/nolocation.png"
                    />
                  </div>
                  <div>暂无定位</div>
                </div></el-tab-pane
              >
            </el-tabs>
          </div>
        </div>
      </CardBox>
    </div>

    <!-- 短信通知 -->
    <el-dialog
      width="640px"
      title="短信通知"
      :visible.sync="textMessageShow"
      append-to-body
      destroy-on-close
    >
      <div class="text-message-box">
        <el-form
          label-position="top"
          ref="messageform"
          :model="messageform"
          :rules="messageRules"
          label-width="80px"
        >
          <el-form-item label="联系人" prop="name" required>
            <el-input
              placeholder="请输入内容"
              v-model="messageform.name"
              class="input-with-select"
            >
              <el-select
                @change="changeRelatName"
                v-model="relatObj"
                value-key="phone"
                slot="append"
              >
                <el-option
                  v-for="(item, index) in relatOptions"
                  :label="item.name"
                  :value="item"
                  :key="item.phone"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="通知电话" prop="phone" required>
            <el-input
              v-model="messageform.phone"
              placeholder="请输入通知电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="通知内容" prop="textContent" required>
            <el-input
              type="textarea"
              :rows="5"
              v-model="messageform.textContent"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="sendSmsSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- TTS语音下发 -->
    <el-dialog
      width="640px"
      title="TTS语音下发"
      :visible.sync="ttsShow"
      append-to-body
      destroy-on-close
      @close="closedDialog"
    >
      <div class="text-message-box">
        <el-form
          label-position="top"
          ref="ttsform"
          :model="ttsform"
          :rules="ttsRules"
          label-width="80px"
        >
          <el-form-item label="快速选择">
            <div class="flex-box">
              <div
                :class="[
                  'tts-item',
                  ttsId == item.dictKey ? 'tts-item-select' : '',
                ]"
                v-for="(item, index) in ttsVoices"
                :key="index"
                @click="ttsSelect(item)"
              >
                {{ item.dictValue }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="通知内容" prop="ttsContent" required>
            <el-input
              type="textarea"
              :rows="5"
              v-model="ttsform.ttsContent"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="sendTtsSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 电话联系 -->
    <el-dialog
      width="640px"
      title="电话联系"
      :visible.sync="phoneShow"
      append-to-body
    >
      <div class="flex-column relat-info">
        <!-- 客户信息 -->
        <div style="margin-bottom: 24px" class="flex-column">
          <div class="relat-info-title">客户信息</div>
          <div class="flex-column">
            <div
              class="flex-box flex-between relat-info-item"
              v-for="(item, index) in warnDetail.customDetailVoList"
            >
              <div class="flex-box m-t-16">
                <div class="relat-info-label">客户名称</div>
                <div class="relat-info-content">
                  {{ item.customName || "---" }}
                </div>
              </div>
              <div class="flex-box m-t-16">
                <div class="relat-info-label">联系人</div>
                <div class="relat-info-content">{{ item.name || "---" }}</div>
              </div>
              <div class="flex-box m-t-16">
                <div class="relat-info-label">联系电话</div>
                <div class="relat-info-content">{{ item.phone || "---" }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 车主信息 -->
        <div style="margin-bottom: 24px" class="flex-column">
          <div class="relat-info-title">车主信息</div>
          <div class="flex-box flex-between relat-info-item">
            <div class="flex-box m-t-16">
              <div class="relat-info-label">车主姓名</div>
              <div class="relat-info-content">
                {{ warnDetail.name || "---" }}
              </div>
            </div>
            <div class="flex-box m-t-16">
              <div class="relat-info-label">联系电话</div>
              <div class="relat-info-content">
                {{ warnDetail.phone || "---" }}
              </div>
            </div>
            <div class="flex-box m-t-16">
              <div class="relat-info-label">紧急联系电话</div>
              <div class="relat-info-content">
                {{ warnDetail.emergencyPhone || "---" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 远程对讲 -->
    <el-dialog
      width="640px"
      title="远程对讲"
      :visible.sync="speakShow"
      append-to-body
      @close="closedDialog"
    >
      <RtpPlayer
        :speakVehicle="speakVehicle"
        :videoGrid="1"
        :displayNum="1"
        :videoCloseTime="realTimeConfig.videoCloseTime"
        :showParameter="false"
        :Z="2"
      ></RtpPlayer>
      <div class="flex-column align-center justify-center">
        <!-- 语音图标 -->
        <div>
          <div v-if="isSpeak">
            <img
              style="width: 300px; height: 30px"
              src="@/assets/images/RiskControl/speaking.gif"
            />
          </div>
          <div v-else>
            <img
              style="width: 225px; height: 87px"
              src="@/assets/images/RiskControl/speak.png"
            />
          </div>
        </div>
        <!-- 提示 -->
        <div style="margin-top: 24px; margin-bottom: 64px">
          <div v-if="!isSpeak">点击“开始对讲”进行远程对讲</div>
          <div v-else>对讲中</div>
        </div>
        <!-- 按钮 -->
        <div style="margin-bottom: 48px">
          <el-button
            @click="speakClick"
            :class="['speak-btn', isSpeak ? 'speak-btn-stop' : '']"
          >
            <div class="flex-box align-center justify-center">
              <img
                style="width: 18px; height: 18px; margin-right: 4px"
                :src="
                  isSpeak
                    ? require('@/assets/images/RiskControl/speakstop.png')
                    : require('@/assets/images/RiskControl/speakstart.png')
                "
              />
              <div v-if="!isSpeak">开始对讲</div>
              <div v-else>结束对讲</div>
            </div>
          </el-button>
        </div>
      </div>
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <div class="flex-center-between">
        <div>
          <el-button type="primary" @click="hadelSpeakShow">远程对讲</el-button>
          <el-button type="primary" @click="ttsShow = true"
            >TTS语音下发</el-button
          >
          <!-- <el-button type="primary" @click="textMessageShow = true"
            >短信通知</el-button
          > -->
          <el-button type="primary" @click="phoneShow = true"
            >电话联系</el-button
          >
        </div>
        <el-button type="info" @click="dialogVisible = false">关闭</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import CardBox from "./CardBox.vue";
import {
  getAlarmRecordDetail,
  sendSms,
  getVehicleOnline,
} from "@/api/riskData.js";
import { getAlarmTypeStyle } from "../../js/index.js";
import { downloadZIP } from "@/utils/index";
import { ttsVoices } from "../../js/dictionary.js";
import cmdWsObj, { CmdType } from "@/utils/cmdWebSocket";
import { addInterveneData } from "@/api/riskData.js";
import RtpPlayer from "@/views/RealTimePositioning/RtpPlayer.vue";
import { mapState } from "vuex";
export default {
  name: "RiskInterventionDialog",
  components: { CardBox, RtpPlayer },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          getAlarmRecordDetail({ id: newValue.id }).then(
            (res) => {
              this.warnDetail = res.data;
              this.relatOptions = res.data.customDetailVoList || [];
            },
            (rej) => {
              this.warnDetail = {};
              this.relatOptions = [];
            }
          );
        } else {
          this.warnDetail = {};
          this.relatOptions = [];
        }
      },
    },
    show(newValue, oldValue) {
      this.dialogVisible = newValue;
    },
    dialogVisible(n, o) {
      this.$emit("update:show", n);
    },
    activeTabs(n) {
      if (n == 2) {
        this.mapinit();
      }
    },
  },
  computed: {
    ...mapState(["userConfig", "pathVehicle"]),
    realTimeConfig() {
      return this.userConfig.realTimeConfig;
    },
  },
  data() {
    return {
      getAlarmTypeStyle: getAlarmTypeStyle,
      speakVehicle: null,
      videoList: [],
      ttsVoices: ttsVoices,
      marker: null,
      mapall: null,
      isSpeak: false,
      vedioType: ["mp4", "avi", "rmvb", "wmv", "flv"],
      dialogVisible: false,
      textMessageShow: false,
      phoneShow: false,
      ttsShow: false,
      chatShow: false,
      speakShow: false,
      warnDetail: {},
      activeTabs: "1",
      ttsId: "",
      infoItems: [
        {
          title: "告警位置",
          key: "alarmAddress",
        },
        {
          title: "持续时长(分钟)",
          key: "duration",
        },
        {
          title: "设备名称",
          key: "deviceName",
        },
        {
          title: "机械编号",
          key: "vin",
        },
        {
          title: "设备类型",
          key: "deviceTypeName",
        },
        {
          title: "所属车组",
          key: "groupName",
        },
        {
          title: "项目名称",
          key: "projectName",
        },
        {
          title: "客户名称",
          key: "customerName",
        },
      ],
      messageform: {
        textContent: "",
        name: "",
        nameKey: "",
        phone: "",
      },
      ttsform: {
        ttsContent: "",
      },
      ttsRules: {
        ttsContent: [
          {
            required: true,
            message: "请输入通知内容",
            trigger: ["change", "blur"],
          },
        ],
      },
      messageRules: {
        name: [
          {
            required: true,
            message: "请输入联系人",
            trigger: ["change", "blur"],
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入通知电话",
            trigger: ["change", "blur"],
          },
        ],
        textContent: [
          {
            required: true,
            message: "请输入通知内容",
            trigger: ["change", "blur"],
          },
        ],
      },
      //联系人
      relatObj: {},
      relatOptions: [],
    };
  },
  methods: {
    mapinit() {
      const that = this;
      let Lng = "";
      let Lat = "";
      // if (this.data.lonLat) {
      //   let lonLat = this.data.lonLat.split(",");
      //   Lng = lonLat[0];
      //   Lat = lonLat[1];
      // }
      Lng = this.data.lon;
      Lat = this.data.lat;
      if (that.mapall) {
        that.mapall.setCenter([Lng, Lat]);
        that.mapall.setZoom(15);
        that.mapall.remove(this.marker);
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: this.data.alarmClassName,
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
      } else {
        var map = new AMap.Map(that.$refs.container, {
          resizeEnable: true,
          center: [Lng, Lat],
          zoom: 15,
        });
        that.mapall = map;
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: this.data.alarmClassName,
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
      }
    },
    downloadFile() {
      if (
        this.warnDetail.alarmAttachmentVOList &&
        this.warnDetail.alarmAttachmentVOList.length
      ) {
        let files = [];
        this.warnDetail.alarmAttachmentVOList.forEach((item) => {
          files.push({
            name: item.id + "." + item.fileType,
            url: item.filePath,
          });
        });
        downloadZIP(files, "附件");
      } else {
        this.$message.error("暂无附件");
      }
    },
    sendSmsSubmit() {
      this.$refs["messageform"].validate((valid) => {
        if (valid) {
          sendSms(this.messageform);
        } else {
          return false;
        }
      });
    },
    changeRelatName(e) {
      this.messageform.name = e.name;
      this.messageform.phone = e.phone;
    },
    ttsSelect(item) {
      this.ttsId = item.dictKey;
      this.ttsform.ttsContent = item.dictValue;
    },
    handleImgClick(url) {
      window.open(url);
    },
    sendTtsSubmit() {
      this.$refs["ttsform"].validate((valid) => {
        if (valid) {
          if (!cmdWsObj.request) throw new Error("命令链路未连接");
          const spareMessage = [
            `【${"终端TTS播报"}】`,
            `【${"通知"}】`,
            `【内容：${this.ttsform.ttsContent}】`,
          ].join("\r\n");
          const cmdData = {
            groupId: this.data.groupId,
            vehicleId: this.data.vehicleId,
            commandType: 1000,
            param: {
              text: this.ttsform.ttsContent,
              textType: 8,
              flags: ["TTS"],
              spareMessage,
            },
          };
          cmdWsObj.request.sendCmdAsync(CmdType.textDownloadSet, cmdData);
          addInterveneData({
            interveneWay: "TTS语音下发",
            frameNo: this.data.vin,
            remark: this.ttsform.ttsContent,
          });
          this.$message.info("指令已下发");
          this.ttsShow = false;
        } else {
          return false;
        }
      });
    },
    speakClick() {
      this.isSpeak = !this.isSpeak;
      if (this.isSpeak) {
        this.speakVehicle = {
          device: this.data.terminalNo,
          protocolType: 1,
          SpecialSign: 0,
          //plate,
          vehicleId: this.data.vehicleId,
          groupId: this.data.groupId,
        };
        addInterveneData({
          interveneWay: "远程对讲",
          frameNo: this.data.vin,
        });
      } else {
        this.speakVehicle = null;
      }
    },
    hadelSpeakShow() {
      getVehicleOnline({ vehicleId: this.data.vehicleId })
        .then((vinfo) => {
          if (vinfo.obj.mediaOnline && vinfo.obj.online) {
            this.speakShow = true;
          } else {
            this.$message({
              message: "设备离线",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "设备离线查询失败",
            type: "error",
          });
        });
    },
    closedDialog() {
      this.ttsform = {
        ttsContent: "",
      };
      this.ttsId = "";
      this.speakVehicle = null;
      this.isSpeak = false;
    },
    closedMainDialog() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-tabs__content {
  border-left: 1px solid #dee3e6;
  border-right: 1px solid #dee3e6;
  border-bottom: 1px solid #dee3e6;
  padding: 16px;
}
::v-deep .el-tabs__header {
  margin: 0;
}
.info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-item {
    width: 400px;
    overflow: hidden;
    display: flex;
    margin-bottom: 24px;
    &-title {
      min-width: 100px;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      text-align: right;
      margin-right: 12px;
    }
    &-content {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
    }
  }
}
.img-box {
  width: 132px;
  height: 132px;
}
.download-file {
  font-weight: 400;
  font-size: 14px;
  color: #3470ff;
  cursor: pointer;
  z-index: 99;
  position: absolute;
  top: 16px;
  right: 0;
}
.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-center-between {
  display: flex;
  justify-content: space-between;
}
.tabs-container {
  margin: 0 24px;
}
.text-message-box {
  padding: 0 30px 30px 30px;
}
.relat-info {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  padding: 16px 30px;
  &-item {
    //margin-top: 16px;
  }
  &-title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
  &-label {
    width: 100px;
    text-align: right;
  }
  &-content {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin-left: 12px;
    width: 100px;
  }
}
.tts-item {
  background: #f5f5f5;
  border-radius: 4px 4px 4px 4px;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin: 10px 10px;
  cursor: pointer;
}
.tts-item-select {
  background: rgba(52, 112, 255, 0.1);
  border: 1px solid rgba(52, 112, 255, 0.2);
  color: #3470ff;
}
.item-warn {
  margin-right: 3px;
  margin-top: 16px;
  &-img {
    width: 185px;
    height: 115px;
    border-radius: 4px;
  }
  &-name {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    max-width: 185px;
    //  省略文字
    overflow: hidden;
  }
}
.warn-img {
  width: 185px;
  height: 115px;
  border-radius: 4px 4px 4px 4px;
}
.map {
  // padding: 16px 0;
  height: 202px;
  width: 100%;
  overflow: auto;
  // border-radius: 0px 8px 8px 8px;
  // border: 1px solid #4278c9;
  &-content {
    width: 128px;
    height: 80px;
    margin-bottom: 8px;
    margin-left: 15px;
    border-radius: 4px;
  }
  &-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
.speak-btn {
  width: 182px;
  height: 40px;
  background: #3470ff;
  border-radius: 20px 20px 20px 20px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.speak-btn-stop {
  background: #f64a2d;
}
// /////////////////
.flex-box {
  display: flex;
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-between {
  justify-content: space-between;
}
.m-t-16 {
  margin-top: 16px;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
</style>
