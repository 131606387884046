<template>
  <div class="analysis-box">
    <!-- 工况 -->
    <div v-if="data.type === 'W'" class="analysis-box-content flex-box">
      <div class="analysis-box-content-title">
        <div>日期</div>
        <!-- <div>天气</div> -->
        <div>作业设备数量</div>
        <div>日平均工时</div>
      </div>
      <div class="analysis-box-content-box flex-box">
        <div
          class="analysis-box-content-item"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div>{{ item.day }}</div>
          <!-- <div class="flex-box flex-items justify-center">
            <img
              :src="getWeatherIcon(item.weather)"
              class="icon"
              style="margin-right: 8px"
            />
            {{ item.weather }}
          </div> -->
          <div>{{ item.workDevice }}</div>
          <div>{{ item.timeDayAvg }}</div>
        </div>
      </div>
    </div>
    <!-- 天气预报 -->
    <div v-else>
      <el-table
        :data="weatherTableData"
        style="width: 100%"
        height="364"
        :row-class-name="tableRowClassName"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column prop="frameNo" label="机械编号">
          <template slot-scope="{ row }">{{ row.frameNo || "---" }}</template>
        </el-table-column>
        <el-table-column prop="deviceType" label="设备类型"
          ><template slot-scope="{ row }">{{
            row.deviceType || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="cityName" label="所在城市"
          ><template slot-scope="{ row }">{{ row.cityName || "---" }}</template>
        </el-table-column>
        <!-- 未来4天气列 -->
        <template v-for="date in weatherDates">
          <el-table-column :prop="date" :label="date">
            <div
              style="display: flex; align-items: center"
              slot-scope="{ row, column, $index }"
            >
              <img
                :src="getWeatherIcon(row[date])"
                class="icon"
                style="margin-right: 8px"
              />
              {{ row[date] }}
            </div>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getWeatherIcon } from "@/utils/weatherTool.js";
import { weatherInfo } from "@/api/weather";
import dayjs from "dayjs";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return { list: [], type: "W" };
      },
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newValue, oldValue) {
        this.tableData = newValue.list || [];
        this.weatherTableData = [];
        // for (let i = 0; i < 4; i++) {
        //   this.weatherDates.push(
        //     dayjs(dayjs().add(i, "day")).format("YYYY-MM-DD")
        //   );
        // }
        // this.tableData = [
        //   {
        //     frameNo: "R0A5200027",
        //     deviceType: "高空车",
        //     cityName: "--",
        //     cityCode: "110101",
        //   },
        // ];
        if (newValue.type === "T" && newValue.list.length && newValue.list) {
          this.tableData.forEach((item) => {
            if (item.cityCode) {
              weatherInfo({
                key: "60f85fa7d1c353b0a2ffb92083e365f0",
                extensions: "all",
                city: item.cityCode,
              }).then((res) => {
                let wps = res.forecasts[0].casts;
                let wpo = {};
                wps.forEach((w) => {
                  wpo[w.date] = w.dayweather;
                });
                item = { ...item, ...wpo };
                this.weatherTableData.push(item);
              });
            } else {
              this.weatherTableData.push(item);
            }
          });
        }
      },
    },
  },
  mounted() {
    for (let i = 0; i < 4; i++) {
      this.weatherDates.push(dayjs(dayjs().add(i, "day")).format("YYYY-MM-DD"));
    }
    // weatherInfo({
    //   key: "60f85fa7d1c353b0a2ffb92083e365f0",
    //   extensions: "all",
    //   city: "110101",
    // }).then((res) => {
    //   console.log(res);
    // });
  },
  data() {
    return {
      getWeatherIcon: getWeatherIcon,
      tableData: [],
      weatherDates: [],
      weatherTableData: [],
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "zdy-row";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    height: 311px;
    overflow: hidden;
    &-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 0;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-right: 16px;
      height: 300px;
    }
    &-box {
      flex: 1;
      overflow: auto;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 160px;
      min-width: 160px;
      height: 300px;
      padding: 24px 0;
      background: #ebf3ff;
      border-radius: 4px;
      margin-left: 16px;
      text-align: center;
      font-size: 14px;
      color: #333333;
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
