<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>风控实施</span>
      <el-select
        v-model="timeType"
        placeholder="请选择"
        class="margin-l"
        @change="handleSelect"
      >
        <el-option
          v-for="item in timeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="analysis-box-content">
      <div class="analysis-box-content-type">
        <div
          class="analysis-box-content-type-item"
          :style="{ backgroundColor: item.bgColor }"
          v-for="(item, index) in typeList"
          :key="index"
        >
          <div class="analysis-box-content-type-item-num">{{ item.value }}</div>
          <div class="analysis-box-content-type-item-text">
            <div
              class="analysis-box-content-type-item-text-circle"
              :style="{ backgroundColor: item.circleColor }"
            ></div>
            {{ item.label }}
          </div>
        </div>
      </div>
      <Chart
        style="height: 220px; width: 100%"
        ref="chartBox"
        :option="gaugeOptions"
      ></Chart>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    details: {
      handler(v) {
        this.typeList[0].value =
          v.riskControlEnforceData.alarmInterventionTotal;
        this.typeList[1].value =
          v.riskControlEnforceData.manualInterventionTotal;
        this.typeList[2].value =
          v.riskControlEnforceData.zeroCompensationCaseTotal;
        var line1 = [];
        var line2 = [];
        var line3 = [];
        var date = v.riskControlEnforceData.riskControlImplementationVoList.map(
          (el) => {
            line1.push(el.alarmInterventionTimes);
            line2.push(el.manualInterventionTimes);
            line3.push(el.zeroCompensationCases);
            return el.correspondingDate;
          }
        );
        this.$nextTick(() => {
          this.gaugeOptions = {
            xAxis: {
              type: "category",
              name: "日期",
              data: date,
              splitLine: {
                lineStyle: {
                  color: "#DEDEDE",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#DEDEDE",
                },
              },
              axisTick: {
                inside: true,
              },
              axisLabel: {
                color: "#333",
              },
              nameTextStyle: {
                color: "#333",
              },
            },
            grid: {
              top: "16%", // 等价于 y: '16%'
              left: "2%",
              right: "10%",
              bottom: "2%",
              containLabel: true,
            },
            yAxis: {
              name: "次数",
              type: "value",
              splitNumber: 9,
              minInterval: 1,
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#DEDEDE",
                },
              },
              axisLabel: {
                color: "#333",
              },
              nameTextStyle: {
                color: "#333",
              },
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            series: [
              {
                name: "告警干预",
                data: line1,
                type: "bar",
                itemStyle: {
                  normal: {
                    color: "rgba(40, 144, 235, 1)",
                  },
                },
              },
              {
                name: "人工干预",
                data: line2,
                type: "bar",
                itemStyle: {
                  normal: {
                    color: "rgba(97, 216, 235, 1)",
                  },
                },
              },
              {
                name: "理赔0赔付案件",
                data: line3,
                type: "bar",
                itemStyle: {
                  normal: {
                    color: "rgba(167, 123, 243, 1)",
                  },
                },
              },
            ],
          };
        });
      },
    },
  },
  data() {
    return {
      timeType: "M",
      timeOptions: [
        {
          label: "本周",
          value: "W",
        },
        {
          label: "本月",
          value: "M",
        },
      ],
      gaugeOptions: {},
      dataGather: {
        nonono: 2222,
      },
      typeList: [
        {
          label: "告警干预次数",
          value: 2222,
          bgColor: "rgba(40, 144, 235, .1)",
          circleColor: "rgba(40, 144, 235, 1)",
        },
        {
          label: "人工干预次数",
          value: 2222,
          bgColor: "rgba(97, 216, 235, .1)",
          circleColor: "rgba(97, 216, 235, 1)",
        },
        {
          label: "理赔0赔付案件",
          value: 2222,
          bgColor: "rgba(167, 123, 243, .1)",
          circleColor: "rgba(167, 123, 243, 1)",
        },
      ],
    };
  },
  created() {},
  methods: {
    handleSelect() {
      this.$emit("changeUnit", this.timeType, "riskUnit");
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
    &-right {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      &-x {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        color: #9c9c9c;
        margin-bottom: 8px;
      }
    }
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    overflow: auto;
    &-type {
      display: flex;
      justify-content: space-around;
      margin-bottom: 14px;
      &-item {
        height: 67px;
        background: rgba(40, 144, 235, 0.1);
        border-radius: 8px;
        padding: 10px 18px;
        text-align: center;
        &-num {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        &-text {
          display: flex;
          justify-content: center;
          align-items: center;
          &-circle {
            width: 16px;
            height: 16px;
            background: #2890eb;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        > div {
          margin-top: 5px;
        }
      }
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
